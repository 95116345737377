import React, {useCallback, useContext, useState} from 'react';
import {Popup, Input, toast, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/headerAccount.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function HeaderAccount({locale, dialog, Dialog, width}) {
    const {restCall} = useContext(NetworkContext);
    const [account, setAccount] = useState(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonConfiguration = () => {
        if (!account) {
            toast({message: locale.headerAccount.g, color: 'danger', display: 'bottom', duration: 3000});
        } else if (account) {
            let item = '';

            if (account.includes('-')) {
                item = account;
            } else {
                const itemDomain = window.location.hostname;
                const itemParts = itemDomain.split('.');
                const itemName = itemParts[itemParts.length - 2];

                item = itemName + '-' + account;
            }

            const message = {
                type: 'rest',
                path: 'account-configuration',
                action: 'get',
                data: {
                    account: item
                }
            };
            restCall(message);
        }
    }

    return (
        <Popup className="cs-header-account-popup" display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'headerAccount'} onClose={DialogClose}>
            <div className="mbsc-grid cs-header-account-header-col">
                <div className="mbsc-row cs-header-account-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-header-account-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-header-account-header-col mbsc-align-center" : "mbsc-col cs-header-account-header-col"} >
                        <Button className="cs-header-account-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.headerAccount.a}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-header-account-header-col mbsc-align-right">
                        <Button disabled={!window.localStorage.account} color="primary" variant="flat" className="mbsc-bold cs-header-account-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-header-account-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.b}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.c}</p>
            </div>
            <div className="cs-header-account-helper">
                <p>{locale.headerAccount.d}</p>
            </div>
            <Input inputStyle="underline" label={locale.headerAccount.e} labelStyle="floating" type="text" name="account" value={account} onChange={ev => {setAccount(ev.target.value) }} />
            <div className="mbsc-button-group-justified cs-header-account-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-header-account-nav-button" onClick={() => ButtonConfiguration()} >
                    {locale.headerAccount.f}
                </Button>
            </div>
        </Popup>
    );
}

export default HeaderAccount;

import React, {useCallback, useContext, useState} from 'react';
import {Popup, Input, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardGridColumnNew.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardGridColumnNew({Dialog, dialog, locale, setLoading, setToast, width, update}) {
    const {wsCall} = useContext(NetworkContext);
    const [name, setName] = useState('');

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        if (!name) {
            setToast({message: locale.dashboardGridColumnNew.f, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemUpdate = update;
            itemUpdate.name = name;

            setLoading(true);

            const message = {
                type: 'wss',
                path: 'grid-add',
                action: 'update',
                data: itemUpdate
            };
            wsCall(message);
        }
    }

    return (
        <Popup className="cs-dashboard-grid-column-new-popup" display="center" width="600" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === ('dashboardGridColumnNew')} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-grid-column-new-header-col">
                <div className="mbsc-row cs-dashboard-grid-column-new-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-grid-column-new-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-grid-column-new-header-col mbsc-align-center" : "mbsc-col cs-dashboard-grid-column-new-header-col"} >
                        <Button className="cs-dashboard-grid-column-new-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardGridColumnNew.a}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-grid-column-new-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-grid-column-new-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-grid-column-new-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardGridColumnNew.b} labelStyle="floating" type="text" name="conversation" value={name} onChange={(ev) => {setName(ev.target.value)}} />
            <div className="mbsc-button-group-justified cs-dashboard-grid-column-new-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-grid-column-new-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardGridColumnNew.d}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardGridColumnNew;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, confirm, setOptions, toast as mbToast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Card, CardTitle, CardSubtitle, CardFooter, CardHeader, Button as MbButton} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspace.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark, faSparkles, faShelves, faFilePlus, faTrashCan, faCloudArrowDown, faFloppyDisk, faFilePen, faMagnifyingGlassChart, faArrowsRotate, faChevronLeft, faWindowRestore, faMagnifyingGlass, faPencil} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import dayjs from "dayjs";
import Truncate from "../utils/Truncate";
import DashboardWorkspaceGraph from "../blocks/DashboardWorkspaceGraph";
import DashboardWorkspaceGrid from "../blocks/DashboardWorkspaceGrid";
import DashboardWorkspaceDoc from "../blocks/DashboardWorkspaceDoc";
import DashboardWorkspaceText from "../blocks/DashboardWorkspaceText";
import DashboardWorkspaceRename from "../dialogs/DashboardWorkspaceRename";
import DashboardWorkspaceAdd from "../dialogs/DashboardWorkspaceAdd";
import DashboardItems from "../dialogs/DashboardItems";
import DashboardItemsRename from "../dialogs/DashboardItemsRename";
import IconFile from "../utils/IconFile";
import Loading from "../utils/Loading";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspace({locale, Navigation, width, setToast, workspace, toast, Dialog, setWorkspace, dialog}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [workspaceItem, setWorkspaceItem] = useState({});
    const [workspaceItemDialog, setWorkspaceItemDialog] = useState({});
    const [myItem, setMyItem] = useState({});
    const [info, setInfo] = useState(false);
    const [loading, setLoading] = useState(false);

    const ItemIconOpen = data => {

        if (data.update) {
            return <FontAwesomeIcon className="cs-dashboard-workspace-icon-update" icon={faWindowRestore} />;
        } else if (data.type === 'graph') {
            return <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faMagnifyingGlassChart} />;
        } else if (data.type === 'data-grid') {
            return <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faMagnifyingGlass} />;
        } else if (data.type === 'text') {
            return <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faPencil} />;
        } else {
            return <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faFilePen} />;
        }
    }

    const ButtonInfo = () => {
        if (info) {
            setInfo(false);
        } else if (!info) {
            setInfo(true);
        }
    }

    const ButtonItems = () => {
        Dialog({view: 'dashboardItems'});
    }

    const ButtonAdd = () => {
        Dialog({view: 'dashboardWorkspaceAdd'});
    }

    const ButtonRemove = data => {
        confirm({
            title: locale.dashboardWorkspace.g,
            message: locale.dashboardWorkspace.h,
            okText: locale.dashboardWorkspace.c,
            cancelText: locale.dashboardWorkspace.i,
            callback: (res) => {
                if (res) {

                    const message = {
                        type: 'wss',
                        path: 'workspace-item',
                        action: 'delete',
                        data: {
                            id: data.id
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonRename = data => {
        setWorkspaceItemDialog(data);
        Dialog({view: 'dashboardWorkspaceRename'});
    }

    const ButtonFileDownload = data => {

        const message = {
            type: 'wss',
            path: 'workspace-file',
            action: 'get',
            data: {
                id: data.id
            }
        };
        wsCall(message);
    }

    const ButtonCsvDownload = data => {
        setToast({message: locale.dashboardWorkspace.s, color: 'info', display: 'bottom'});

        const message = {
            type: 'wss',
            path: 'workspace-csv',
            action: 'get',
            data: {
                id: data.id
            }
        };
        wsCall(message);
    }

    const ButtonOpen = data => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item',
            action: 'get',
            data: {
                id: data.id
            }
        };
        wsCall(message);
    }

    const ButtonBack = () => {
        setWorkspaceItem({});
    }

    const ButtonSave = data => {

        const message = {
            type: 'wss',
            path: 'item',
            action: 'put',
            data: {
                id: data.id
            }
        };
        wsCall(message);
    }

    const InputPaste = data => {
        data.preventDefault();
        const items = data.clipboardData.items;
        setToast({message: locale.dashboardWorkspace.m, color: 'info', display: 'bottom'});

        for (const item of items) {
            const itemFile = item.getAsFile();

            const reader = new FileReader();
            reader.onloadend = () => {

                const workspaceItem = reader.result;
                const itemName = itemFile.name;
                const itemType = itemFile.type;

                const message = {
                    type: 'wss',
                    path: 'workspace-file',
                    action: 'put',
                    data: {
                        file: workspaceItem,
                        name: itemName,
                        type: itemType
                    }
                };
                wsCall(message);
            };
            reader.readAsDataURL(itemFile);
        }
    }

    const InputDrop = data => {
        data.preventDefault();
        const items = data.dataTransfer.items;
        setToast({message: locale.dashboardWorkspace.m, color: 'info', display: 'bottom'});

        for (const item of items) {
            const itemFile = item.getAsFile();

            const reader = new FileReader();
            reader.onloadend = () => {

                const workspaceItem = reader.result;
                const itemName = itemFile.name;
                const itemType = itemFile.type;

                const message = {
                    type: 'wss',
                    path: 'workspace-file',
                    action: 'put',
                    data: {
                        file: workspaceItem,
                        name: itemName,
                        type: itemType
                    }
                };
                wsCall(message);
            };
            reader.readAsDataURL(itemFile);
        }
    }

    const PutItem = useCallback(data => {
        if (data.toast){
            mbToast({message: locale.dashboardWorkspace.o, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.error){
            mbToast({message: locale.dashboardWorkspace.p, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.dashboardWorkspace.o, locale.dashboardWorkspace.p]);

    const GetWorkspaceItem = useCallback(data => {
        if (data.item) {
            setWorkspaceItem(data.item);
        }
        setLoading(false);
    }, []);

    const GetWorkspaceCsv = useCallback(data => {
        if (data.rows) {
            const itemDate = dayjs().valueOf().toString();
            const csvContent = data.rows.map(row => row.join(',')).join('\n');

            const downloadLink = document.createElement('a');
            downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvContent);
            downloadLink.download = itemDate + '.csv';
            downloadLink.style.display = 'none';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }, []);

    const UpdateWorkspaceItemAccept = useCallback(data => {
        if (data.item) {
            setWorkspaceItem(data.item);
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        setLoading(false);
    }, []);

    const UpdateWorkspaceItemReject = useCallback(data => {
        if (data.item) {
            setWorkspaceItem(data.item);
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        setLoading(false);
    }, []);

    const GetWorkspaceFile = useCallback(data => {
        if (data.item) {
            let dataUrl;

            if (data.item.data.type === 'image') {

                dataUrl = "data:" + data.item.data.mime + ";base64," + data.item.file;

            } else if (data.item.data.subType === 'pdf') {

                const blob = new Blob([data.item.file], { type: data.item.data.mime });
                dataUrl = URL.createObjectURL(blob);

            } else if (data.item.data.subType === 'json' || data.item.data.type === 'text') {

                const blob = new Blob([data.item.file], { type: data.item.data.mime });
                dataUrl = URL.createObjectURL(blob);

            }

            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = data.item.data.name;
            downloadLink.style.display = 'none';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            if (data.item.data.type !== 'image') {
                URL.revokeObjectURL(dataUrl);
            }
        }
    }, []);

    const PutWorkspaceFile = useCallback(data => {
        if (data.toast){
            mbToast({message: locale.dashboardWorkspace.f, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
        if (data.error){
            mbToast({message: locale.dashboardWorkspace.n, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.dashboardWorkspace.f, Dialog, dialog.view, locale.dashboardWorkspace.n]);

    const DeleteWorkspaceItem = useCallback(data => {
        if (data.toast){
            mbToast({message: locale.dashboardWorkspace.f, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
    }, [locale.dashboardWorkspace.f, Dialog, dialog.view]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'workspace-item') {
            DeleteWorkspaceItem(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteWorkspaceItem, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'workspace-file') {
            PutWorkspaceFile(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, PutWorkspaceFile, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'workspace-file') {
            GetWorkspaceFile(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetWorkspaceFile, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'workspace-item') {
            GetWorkspaceItem(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetWorkspaceItem, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'workspace-csv') {
            GetWorkspaceCsv(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, GetWorkspaceCsv, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'workspace-item-accept') {
            UpdateWorkspaceItemAccept(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateWorkspaceItemAccept, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'workspace-item-reject') {
            UpdateWorkspaceItemReject(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateWorkspaceItemReject, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'item') {
            PutItem(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutItem, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-dashboard-workspace-header">
                <div className="mbsc-col cs-dashboard-workspace-col-start">
                    <Button onClick={() => ButtonInfo()} color="secondary" variant="flat"  className="mbsc-bold cs-dashboard-workspace-color" >
                        <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faSparkles} />{width > 768 && '\u00A0'}
                        {info &&
                            <div className="cs-dashboard-workspace-menu-text">
                                {locale.dashboardWorkspace.a}
                            </div>
                        }
                    </Button>
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-workspace-col-end">
                        {Empty(workspaceItem) &&
                            <Button id="dashboardWorkspaceAdd" disabled={Boolean(!Empty(toast))} color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonAdd()} >
                                <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faFilePlus} />{width > 768 && <div className="cs-dashboard-workspace-menu-text">{locale.dashboardWorkspace.e}</div>}
                            </Button>
                        }
                        {Empty(workspaceItem) &&
                            <Button disabled={Boolean(!Empty(toast))} color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonItems()} >
                                <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faShelves} />{width > 768 && <div className="cs-dashboard-workspace-menu-text">{locale.dashboardWorkspace.b}</div>}
                            </Button>
                        }
                        {!Empty(workspaceItem) && (workspaceItem.type === 'graph' || workspaceItem.type === 'data-grid' || workspaceItem.type === 'document') &&
                            <Button disabled={Boolean(!Empty(toast))} color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonBack()} >
                                <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faChevronLeft} />{width > 768 && <div className="cs-dashboard-workspace-menu-text">{locale.dashboardWorkspace.q}</div>}
                            </Button>
                        }
                        {!Empty(workspaceItem) && (workspaceItem.type === 'graph' || workspaceItem.type === 'data-grid' || workspaceItem.type === 'document') &&
                            <Button disabled={Boolean(!Empty(toast))} color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonOpen(workspaceItem.id)} >
                                <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faArrowsRotate} />{width > 768 && <div className="cs-dashboard-workspace-menu-text">{locale.dashboardWorkspace.r}</div>}
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-color" onClick={() => Navigation({card: 'close'})} >
                            <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {!loading && Empty(workspaceItem) &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-main" contentEditable suppressContentEditableWarning={true} onPaste={InputPaste} onDragOver={(e) => e.preventDefault()} onDrop={InputDrop}>
                    {workspace && workspace.length !== 0 && workspace.map(item => {
                        return (
                            <div className="mbsc-col-12 mbsc-col-sm-6 mbsc-col-lg-4 mbsc-col-xl-3">
                                <Card theme="ios" themeVariant="light" contentEditable="false">
                                    <CardHeader theme="ios" themeVariant="light" className="mbsc-align-center">
                                        {IconFile(item)}
                                        <CardTitle theme="ios" themeVariant="light" className="cs-dashboard-workspace-cursor" onDoubleClick={() => ButtonRename(item)} >
                                            {Truncate(item.name, 15)}
                                        </CardTitle>
                                        <CardSubtitle theme="ios" themeVariant="light">
                                            {item.subtitle}
                                        </CardSubtitle>
                                    </CardHeader>
                                    <CardFooter theme="ios" themeVariant="light" className="mbsc-align-right cs-dashboard-workspace-footer" >
                                        {!(item.type === 'file' || item.type === 'data-csv') &&
                                            <MbButton disabled={Boolean(!Empty(toast))} title={locale.dashboardWorkspace.l} theme="ios" themeVariant="light" color="primary" flat={true} className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonOpen(item)} >
                                                {ItemIconOpen(item)}
                                            </MbButton>
                                        }
                                        {item.type === 'file' &&
                                            <MbButton disabled={Boolean(!Empty(toast))} title={locale.dashboardWorkspace.j} theme="ios" themeVariant="light" color="primary" flat={true} className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonFileDownload(item)} >
                                                <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faCloudArrowDown} />
                                            </MbButton>
                                        }
                                        {item.type === 'data-csv' &&
                                            <MbButton disabled={Boolean(!Empty(toast))} title={locale.dashboardWorkspace.j} theme="ios" themeVariant="light" color="primary" flat={true} className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonCsvDownload(item)} >
                                                <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faCloudArrowDown} />
                                            </MbButton>
                                        }
                                        <MbButton disabled={Boolean(!Empty(toast))} title={locale.dashboardWorkspace.k} theme="ios" themeVariant="light" color="primary" flat={true} className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonSave(item)} >
                                            <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faFloppyDisk} />
                                        </MbButton>
                                        <MbButton disabled={Boolean(!Empty(toast))} title={locale.dashboardWorkspace.c} theme="ios" themeVariant="light" color="primary" flat={true} className="mbsc-bold cs-dashboard-workspace-color" onClick={() => ButtonRemove(item)} >
                                            <FontAwesomeIcon className="cs-dashboard-workspace-icon" icon={faTrashCan} />
                                        </MbButton>
                                    </CardFooter>
                                </Card>
                            </div>
                        );
                    })}
                    {(!workspace || workspace.length === 0) &&
                        <Card theme="ios" themeVariant="light" contentEditable="false">
                            <CardHeader theme="ios" themeVariant="light" className="mbsc-align-center">
                                {IconFile({type: 'empty'})}
                                <CardTitle theme="ios" themeVariant="light" >
                                    {locale.dashboardWorkspace.d}
                                </CardTitle>
                            </CardHeader>
                        </Card>
                    }
                </div>
            }
            {!loading && !Empty(workspaceItem) && workspaceItem.type === 'graph' &&
                <DashboardWorkspaceGraph
                    locale={locale}
                    workspaceItem={workspaceItem}
                    setLoading={setLoading}
                />
            }
            {!loading && !Empty(workspaceItem) && workspaceItem.type === 'document' &&
                <DashboardWorkspaceDoc
                    locale={locale}
                    workspaceItem={workspaceItem}
                    setLoading={setLoading}
                />
            }
            {!loading && !Empty(workspaceItem) && workspaceItem.type === 'text' &&
                <DashboardWorkspaceText
                    locale={locale}
                    workspaceItem={workspaceItem}
                    setLoading={setLoading}
                />
            }
            {!loading && !Empty(workspaceItem) && workspaceItem.type === 'data-grid' &&
                <DashboardWorkspaceGrid
                    locale={locale}
                    width={width}
                    setToast={setToast}
                    Dialog={Dialog}
                    workspaceItem={workspaceItem}
                    dialog={dialog}
                    loading={loading}
                    setLoading={setLoading}
                />
            }
            {loading &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-main" >
                    <Loading />
                </div>
            }
            {dialog.view === 'dashboardWorkspaceAdd' &&
                <DashboardWorkspaceAdd
                    dialog={dialog}
                    Dialog={Dialog}
                    locale={locale}
                    setToast={setToast}
                />
            }
            {dialog.view === 'dashboardWorkspaceRename' &&
                <DashboardWorkspaceRename
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    workspaceItemDialog={workspaceItemDialog}
                    setWorkspace={setWorkspace}
                />
            }
            {dialog.view === 'dashboardItems' &&
                <DashboardItems
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    setMyItem={setMyItem}
                    setWorkspace={setWorkspace}
                />
            }
            {dialog.view === 'dashboardItemsRename' &&
                <DashboardItemsRename
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    width={width}
                    myItem={myItem}
                />
            }
        </>
    );
}

export default DashboardWorkspace;

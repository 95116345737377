import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Input, toast, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderConversationRename.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderConversationRename({locale, dialog, Dialog, width, archive}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [label, setLabel] = useState(archive.text);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ItemSave = () => {
        if (!label) {
            toast({message: locale.dashboardHeaderConversationRename.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (label) {

            const message = {
                type: 'wss',
                path: 'archive-name',
                action: 'update',
                data: {
                    conversation: archive.timestamp,
                    text: label
                }
            };
            wsCall(message);
        }
    }

    const ButtonClose = () => {
        if (label !== archive.text) {
            ItemSave();
        } else {
            Dialog({view: 'dashboardHeaderConversations'});
        }
    }

    const UpdateArchiveName = useCallback(data => {
        if (data.toast) {
            Dialog({view: 'dashboardHeaderConversations'});
        }
    }, [Dialog]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'archive-name') {
            UpdateArchiveName(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateArchiveName, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-conversation-rename-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderConversationRename'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-conversation-rename-header-col">
                <div className="mbsc-row cs-dashboard-header-conversation-rename-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-conversation-rename-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-conversation-rename-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-conversation-rename-header-col"} >
                        <Button className="cs-dashboard-header-conversation-rename-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderConversationRename.e}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-conversation-rename-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-conversation-rename-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-conversation-rename-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardHeaderConversationRename.b} labelStyle="floating" type="text" name="account" value={label} onChange={ev => setLabel(ev.target.value)} />
        </Popup>
    );
}

export default DashboardHeaderConversationRename;

import React, {useCallback} from 'react';
import {Popup, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderTabs.css';
import Truncate from "../utils/Truncate";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderTabs({dialog, Dialog, tabs, width, NavTab, navigation}) {

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ListItem = data => {
        return <li key={data.item.id} className="cs-dashboard-header-tabs-li">
            <Button disabled={navigation.tab === data.item.id} color="primary" variant="flat" className="cs-dashboard-header-tabs-button mbsc-bold" onClick={() => ListOpen(data.item)} >
                {width < 992 ? Truncate(data.item.name, 15) : Truncate(data.item.text, 30)}
            </Button>
        </li>;
    }

    const ListOpen = data => {
        NavTab(data);
        DialogClose();
    }

    return (
        <Popup className="cs-dashboard-header-tabs-popup" display="anchored" anchor={document.getElementById('dashboardHeaderTabs')} showArrow={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderTabs'} onClose={DialogClose}>
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={tabs}
            />
        </Popup>
    );
}

export default DashboardHeaderTabs;

import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Popup, Input, Datepicker, Textarea, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardCalendarNew.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendarNew({Dialog, dialog, locale, visit, setRefresh, width, setToast, anchor, instance, startTime, endTime, date}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [title, setTitle] = useState(null);
    const [location, setLocation] = useState(null);
    const [start, setStart] = useState(visit.start ? visit.start : null);
    const [end, setEnd] = useState(visit.end ? visit.end : null);
    const [color, setColor] = useState(null);
    const [notes, setNotes] = useState(null);

    const DialogClose = useCallback(() => {
        if (!Empty(instance)) {
            instance.removeEvent(visit.id);
        }
        Dialog({view: 'close'});
    }, [Dialog, instance, visit.id]);

    const ButtonSave = () => {
        if (!title || !start || !end) {
            setToast({message: locale.dashboardCalendarNew.l, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardCalendarNew.m, color: 'info', display: 'bottom', duration: 1000});

            const message = {
                type: 'wss',
                path: 'calendar',
                action: 'put',
                data: {
                    title: title,
                    location: location,
                    start: dayjs(start).format(),
                    end: dayjs(end).format(),
                    color: color,
                    notes: notes
                }
            };
            wsCall(message);
        }
    }

    const ButtonHeader = () => {
        if (anchor) {
            const itemDiff = dayjs(visit.end);

            return dayjs(visit.start).format("h:mm A") + ' | ' + itemDiff.diff(visit.start, 'minute') + ' ' + locale.dashboardCalendarNew.q;
        } else if (!anchor) {

            return locale.dashboardCalendarNew.o;
        }
    }

    const PutCalendar = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
        }
        DialogClose();
    }, [DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'calendar') {
            PutCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutCalendar, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-new-popup" width={600} anchor={anchor ? anchor : null} display={anchor ? "anchored" : "center"} scrollLock={true} showArrow={false} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardCalendarNew'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-calendar-new-header-col">
                <div className="mbsc-row cs-dashboard-calendar-new-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-calendar-new-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-calendar-new-header-col mbsc-align-center" : "mbsc-col cs-dashboard-calendar-new-header-col"} >
                        <Button className="cs-dashboard-calendar-new-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {ButtonHeader()}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-calendar-new-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-new-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-calendar-new-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {!anchor &&
                <div className="mbsc-grid cs-dashboard-calendar-new-grid">
                    <div className="mbsc-row cs-dashboard-calendar-new-row">
                        <div className="mbsc-col cs-dashboard-calendar-new-grid">
                            <Datepicker
                                controls={['calendar', 'timegrid']}
                                touchUi={width <= 576}
                                inputStyle="underline"
                                label={locale.dashboardCalendarNew.c}
                                labelStyle="floating"
                                value={start}
                                selectMultiple={false}
                                stepMinute={15}
                                minTime={startTime}
                                maxTime={endTime}
                                onChange={(args) => setStart(args.value)}
                                defaultSelection={date}
                                buttons={['set', 'cancel']}
                                setText={locale.dashboardCalendarNew.n}
                                cancelText={locale.dashboardCalendarNew.p}
                            />
                        </div>
                        <div className="mbsc-col cs-dashboard-calendar-new-grid">
                            <Datepicker
                                controls={['calendar', 'timegrid']}
                                touchUi={width <= 576}
                                inputStyle="underline"
                                label={locale.dashboardCalendarNew.d}
                                labelStyle="floating"
                                value={end}
                                selectMultiple={false}
                                stepMinute={15}
                                minTime={start ? start : startTime}
                                maxTime={endTime}
                                onChange={(args) => setEnd(args.value)}
                                defaultSelection={start ? start : date}
                                buttons={['set', 'cancel']}
                                setText={locale.dashboardCalendarNew.n}
                                cancelText={locale.dashboardCalendarNew.p}
                            />
                        </div>
                    </div>
                </div>
            }
            <Input inputStyle="underline" label={locale.dashboardCalendarNew.a} labelStyle="floating" type="text" name="title" value={title} onChange={(ev) => {setTitle(ev.target.value)}} />
            <mobiscroll.Color theme="ios" themeVariant="light" touchUi={false} value={color} onSet={event => setColor(event.value)}>
                <mobiscroll.Input theme="ios" themeVariant="light" inputStyle="underline" >
                    {locale.dashboardCalendarNew.e}
                </mobiscroll.Input>
            </mobiscroll.Color>
            <Input inputStyle="underline" label={locale.dashboardCalendarNew.b} labelStyle="floating" type="text" name="location" value={location} onChange={(ev) => {setLocation(ev.target.value)}} />
            <div className="cs-dashboard-calendar-new-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardCalendarNew.f} value={notes} onChange={(ev) => setNotes(ev.target.value)} />
            </div>
            <div className="mbsc-button-group-justified cs-dashboard-calendar-new-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-new-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardCalendarNew.k}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardCalendarNew;

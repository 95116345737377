import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Popup, Input, setOptions, Button, toast, confirm} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderConversationSave.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {Listview} from "@mobiscroll/react4";
import dayjs from "dayjs";
import Truncate from "../utils/Truncate";
import Loading from "../utils/Loading";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderConversationSave({locale, dialog, Dialog, setToast, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [conversation, setConversation] = useState(null);
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const Conversations = () => {
        const message = {
            type: 'wss',
            path: 'conversation-archives',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const ButtonSave = () => {
        if (!conversation) {
            toast({message: locale.dashboardHeaderConversationSave.e, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'conversation-archive',
                action: 'put',
                data: {
                    conversation: conversation
                }
            };
            wsCall(message);
        }
    }

    const ListItem = data => {
        const itemDate = dayjs(parseInt(data.item.timestamp)).format("MMMM D, YYYY @ h:mm A");

        return <li key={data.item.timestamp} onClick={() => ClickOverwrite(data.item)}>
            <div className="mbsc-bold mbsc-txt-muted">{width < 992 ? Truncate(data.item.text, 15) : Truncate(data.item.text, 30)}</div>
            <div className="mbsc-row mbsc-txt-muted mbsc-txt-s cs-dashboard-header-conversations-item-txt">
                {itemDate}
            </div>
        </li>;
    }

    const ClickOverwrite = data => {
        console.log(data);
        confirm({
            title: locale.dashboardHeaderConversationSave.i,
            message: locale.dashboardHeaderConversationSave.j,
            okText: locale.dashboardHeaderConversationSave.l,
            cancelText: locale.dashboardHeaderConversationSave.k,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'conversation-archive',
                        action: 'update',
                        data: {
                            conversation: data.timestamp
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const PutConversationArchive = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardHeaderConversationSave.a, color: 'info', display: 'bottom', duration: 1000});
            DialogClose();
        }
    }, [DialogClose, locale.dashboardHeaderConversationSave.a]);

    const GetConversationArchives = useCallback(data => {
        if (data.conversations) {
            setConversations(data.conversations);
        }
        setLoading(false);
    }, []);

    const UpdateConversationArchive = useCallback(data => {
        if (data.toast) {
            DialogClose();
        }
    }, [DialogClose]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-archive') {
            UpdateConversationArchive(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationArchive, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'conversation-archives') {
            GetConversationArchives(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetConversationArchives, setWsResponse]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'conversation-archive') {
            PutConversationArchive(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutConversationArchive, setWsResponse]);

    useEffect(() => {
        Conversations();
    }, [])

    return (
        <Popup className="cs-dashboard-header-conversation-save-popup" scrollLock={true} display="center" width="600" buttons={[]} isOpen={dialog.view === 'dashboardHeaderConversationSave'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-conversation-save-header-col">
                <div className="mbsc-row cs-dashboard-header-conversation-save-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-conversation-save-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-conversation-save-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-conversation-save-header-col"} >
                        <Button className="cs-dashboard-header-conversation-save-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderConversationSave.b}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-conversation-save-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-conversation-save-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-conversation-save-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-dashboard-header-conversation-save-helper" >
                <p>{locale.dashboardHeaderConversationSave.h}</p>
            </div>
            <Input inputStyle="underline" label={locale.dashboardHeaderConversationSave.d} labelStyle="floating" type="text" name="conversation" value={conversation} onChange={(ev) => {setConversation(ev.target.value)}} />
            <div className="mbsc-button-group-justified cs-dashboard-header-conversation-save-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-conversation-save-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardHeaderConversationSave.c}
                </Button>
            </div>
            {loading &&
                <Loading />
            }
            {!loading &&
                <div className="cs-dashboard-header-conversation-save-helper" >
                    <p>{(conversations && conversations.length === 0) ? locale.dashboardHeaderConversationSave.f : locale.dashboardHeaderConversationSave.g}</p>
                </div>
            }
            {!loading && conversations && conversations.length !== 0 &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={conversations}
                />
            }
        </Popup>
    );
}

export default DashboardHeaderConversationSave;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Button, Input, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardNotes.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileCirclePlus, faLeft, faFloppyDisk, faTrashCan, faXmark, faChevronLeft, faSparkles} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardNotes({locale, Navigation, width, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [note, setNote] = useState({});
    const [notes, setNotes] = useState([]);
    const [children, setChildren] = useState([]);
    const [group, setGroup] = useState(null);
    const [list, setList] = useState('');
    const [item, setItem] = useState('');
    const [title, setTitle] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [reload, setReload] = useState(false);
    const [info, setInfo] = useState(false);

    const ListItem = data => {
        return <li>
            {children.length !== 0 && data.item.id === 0 && <FontAwesomeIcon icon={faChevronLeft} />} <span className="mbsc-bold mbsc-txt-muted">{data.item.name}</span>
        </li>;
    }

    const ListOpen = data => {
        setReload(true);

        if (children.length === 0) {
            setGroup(data);
            setChildren(notes[data].children);
        } else if (children.length !== 0 && children[data].id === 0) {
            setGroup(null);
            setChildren([]);
        } else {
            setToast({message: locale.dashboardNotes.g, color: 'info', display: 'bottom', duration: 1000});

            const message = {
                type: 'wss',
                path: 'note',
                action: 'get',
                data: {
                    note: notes[group].children[data].id
                }
            };
            wsCall(message);
        }
    }

    const EditorUpdate = data => {
        setItem(data);
    }

    const ButtonBack = () => {
        setNote({});
    }

    const ButtonSave = () => {
        if (!item || !title) {
            setToast({message: locale.dashboardNotes.j, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardNotes.k, color: 'info', display: 'bottom', duration: 1000});

            const message = {
                type: 'wss',
                path: 'note',
                action: note.id ? 'update' : 'put',
                data: {
                    note: note,
                    item: item,
                    title: title,
                    group: list
                }
            };
            wsCall(message);
        }
    }

    const ButtonNew = () => {
        let itemGroup = '';
        if (group !== null) {
            itemGroup = notes[group].name;
        } else {
            itemGroup = 'General';
        }
        setNote({group: itemGroup, title: '', item: ''});
        setList(itemGroup);
        setTitle('');
        setItem('');
    }

    const ButtonDelete = () => {
        confirm({
            title: locale.dashboardNotes.l,
            message: locale.dashboardNotes.m,
            okText: locale.dashboardNotes.o,
            cancelText: locale.dashboardNotes.n,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'note',
                        action: 'delete',
                        data: {
                            note: note
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonInfo = () => {
        if (info) {
            setInfo(false);
        } else if (!info) {
            setInfo(true);
        }
    }

    const Notes = useCallback(() => {
        if (Empty(note)) {
            setToast({message: locale.dashboardNotes.e, color: 'info', display: 'bottom', duration: 1000});
        }

        const message = {
            type: 'wss',
            path: 'notes',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }, [locale.dashboardNotes.e, wsCall, note]);

    const GetNote = useCallback(data => {
        if (data.note) {
            setNote(data.note);
            setItem(data.note.item);
            setTitle(data.note.title);
            setList(data.note.group);
        }
    }, []);

    const GetNotes = useCallback(data => {
        if (data.notes.length !== 0) {
            setNotes(data.notes);
        }
        if (data.notes.length === 0) {
            setToast({message: locale.dashboardNotes.f, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (refresh) {
            setNote({});
            setGroup(null);
            setChildren([]);
            setRefresh(false);
        }
    }, [locale.dashboardNotes.f, refresh]);

    const PostNote = useCallback(data => {
        if (data.toast) {
            Notes();
        }
    }, [Notes]);

    const DeleteNote = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'note') {
            DeleteNote(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteNote, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'post' && wsResponse.path === 'note') {
            PostNote(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PostNote, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'notes') {
            GetNotes(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetNotes, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'note') {
            GetNote(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetNote, setWsResponse]);

    useEffect(() => {
        if (refresh) {
            Notes();
        }
    }, [refresh, Notes]);

    useEffect(() => {
        if (reload) {
            setReload(false);
        }
    }, [reload]);

    useEffect(() => {
        Notes();
    }, []);

    return (
        <>
            <div className="mbsc-row cs-dashboard-notes-header">
                <div className="mbsc-col cs-dashboard-notes-col-start">
                    <Button onClick={() => ButtonInfo()} color="secondary" variant="flat"  className="mbsc-bold cs-dashboard-notes-color" >
                        <FontAwesomeIcon className="cs-dashboard-notes-icon" icon={faSparkles} />{width > 768 && '\u00A0'}
                        {info &&
                            <div className="cs-dashboard-notes-menu-text">
                                {locale.dashboardNotes.c}
                            </div>
                        }
                    </Button>
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-notes-col-end">
                        {!Empty(note) &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-notes-color" onClick={() => ButtonBack()} >
                                <FontAwesomeIcon className="cs-dashboard-notes-icon" icon={faLeft} />{width > 768 && <div className="cs-dashboard-notes-menu-text">{locale.dashboardNotes.p}</div>}
                            </Button>
                        }
                        {!Empty(note) &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-notes-color"  onClick={() => ButtonSave()} >
                                <FontAwesomeIcon className="cs-dashboard-notes-icon" icon={faFloppyDisk} />{width > 768 && <div className="cs-dashboard-notes-menu-text">{locale.dashboardNotes.q}</div>}
                            </Button>
                        }
                        {!Empty(note) && note.id &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-notes-color"  onClick={() => ButtonDelete()} >
                                <FontAwesomeIcon className="cs-dashboard-notes-icon" icon={faTrashCan} />{width > 768 && <div className="cs-dashboard-notes-menu-text">{locale.dashboardNotes.o}</div>}
                            </Button>
                        }
                        {Empty(note) &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-notes-color"  onClick={() => ButtonNew()} >
                                <FontAwesomeIcon className="cs-dashboard-notes-icon" icon={faFileCirclePlus} />{width > 768 && <div className="cs-dashboard-notes-menu-text">{locale.dashboardNotes.r}</div>}
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-notes-color" onClick={() => Navigation({card: 'close'})} >
                            <FontAwesomeIcon className="cs-dashboard-notes-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            {!Empty(note) &&
                <div className="mbsc-row cs-dashboard-notes-main">
                    <div className="mbsc-col cs-dashboard-notes-editor">
                        {/*<CKEditor id="editor-focus" config={{ui:{poweredBy:{position:'inside', side:'right'}}}} editor={Editor} data={item} onChange={(event, editor) => {EditorUpdate(editor.getData())}} disableWatchdog={true} />*/}
                    </div>
                </div>
            }
            {!Empty(note) &&
                <div className="mbsc-row cs-dashboard-notes-footer">
                    <div className="mbsc-col cs-dashboard-notes-col">
                        <Input inputStyle="outline" className="cs-dashboard-notes-input" label={locale.dashboardNotes.h} labelStyle="floating" type="text" name="title" value={title} onChange={(ev) => {setTitle(ev.target.value)}} />
                    </div>
                    <div className="mbsc-col cs-dashboard-notes-col">
                        <Input inputStyle="outline" className="cs-dashboard-notes-input" label={locale.dashboardNotes.i} labelStyle="floating" type="text" name="list" value={list} onChange={(ev) => {setList(ev.target.value)}} />
                    </div>
                </div>
            }
            {!reload && !refresh && Empty(note) &&
                <Listview
                    theme="ios"
                    themeVariant="light"
                    select="single"
                    itemType={ListItem}
                    data={children.length !== 0 ? children : notes}
                    onItemTap={event => ListOpen(event.index)}
                />
            }
        </>
    );
}

export default DashboardNotes;

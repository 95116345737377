import React, {useCallback, useContext, useRef, useEffect} from 'react';
import {Popup, Button, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardCalendarDisplay.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendarDisplay({Dialog, dialog, locale, display, setDisplay, ui, setUi}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const menu = useRef(locale.dashboardCalendarDisplay.a);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ListItem = data => {
        let item = '';

        if (data.item.agenda) {
            item = <li key={data.item.timestamp} className="cs-dashboard-calendar-display-li">
                <Button disabled={display === 'agenda'} color="primary" variant="flat" className="cs-dashboard-calendar-display-button mbsc-bold" onClick={() => ButtonSelect('agenda')} >
                    {locale.dashboardCalendarDisplay.b}
                </Button>
            </li>;
        } else if (data.item.scheduler) {
            item = <li key={data.item.timestamp} className="cs-dashboard-calendar-display-li">
                <Button disabled={display === 'scheduler'} color="primary" variant="flat" className="cs-dashboard-calendar-display-button mbsc-bold" onClick={() => ButtonSelect('scheduler')} >
                    {locale.dashboardCalendarDisplay.c}
                </Button>
            </li>;
        }

        return item;
    }

    const ButtonSelect = data => {
        setDisplay(data);

        const message = {
            type: 'wss',
            path: 'ui-calendar-display',
            action: 'update',
            data: {
                display: data,
                ui: ui
            }
        };
        wsCall(message);
    }

    const UpdateUiCalendarDisplay = useCallback(data => {
        if (data.toast){
            toast({message: locale.dashboardCalendarDisplay.d, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.ui){
            setUi(data.ui);
        }
        DialogClose();
    }, [locale.dashboardCalendarDisplay.d, DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'ui-calendar-display') {
            UpdateUiCalendarDisplay(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUiCalendarDisplay, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-display-popup" display="anchored" anchor={document.getElementById('dashboardCalendarDisplay')} showArrow={false} buttons={[]} isOpen={dialog.view === 'dashboardCalendarDisplay'} onClose={DialogClose} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardCalendarDisplay;

import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Popup, Input, Checkbox, Datepicker, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '../styles/dashboardTodosNew.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardTodosNew({Dialog, dialog, locale, setRefresh, width, groupText, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [item, setItem] = useState(null);
    const [list, setList] = useState(groupText ? groupText : null);
    const [completed, setCompleted] = useState(false);
    const [due, setDue] = useState(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        if (!item) {
            setToast({message: locale.dashboardTodosNew.e, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardTodosNew.f, color: 'info', display: 'bottom', duration: 1000});
            if (due) {
                setDue(dayjs(due).format());
            }

            const message = {
                type: 'wss',
                path: 'todo',
                action: 'put',
                data: {
                    item: item,
                    completed: completed,
                    due: due,
                    list: list
                }
            };
            wsCall(message);
        }
    }

    const PutTodo = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
        }
        DialogClose();
    }, [DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'todo') {
            PutTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutTodo, setWsResponse]);

    return (
        <Popup className="cs-dashboard-todos-new-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardTodosNew'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-todos-new-header-col">
                <div className="mbsc-row cs-dashboard-todos-new-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-todos-new-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-todos-new-header-col mbsc-align-center" : "mbsc-col cs-dashboard-todos-new-header-col"} >
                        <Button className="cs-dashboard-todos-new-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardTodosNew.d}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-todos-new-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-todos-new-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-todos-new-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardTodosNew.h} labelStyle="floating" type="text" name="list" value={list} onChange={(ev) => setList(ev.target.value)} />
            <Input inputStyle="underline" label={locale.dashboardTodosNew.a} labelStyle="floating" type="text" name="title" value={item} onChange={(ev) => {setItem(ev.target.value)}} />
            <Datepicker
                controls={['date']}
                touchUi={width <= 576}
                inputStyle="underline"
                label={locale.dashboardTodosNew.c}
                labelStyle="floating"
                buttons={['set', 'cancel']}
                value={due}
                selectMultiple={false}
                onChange={args => setDue(args.value)}
            />
            <Checkbox
                color="info"
                label={locale.dashboardTodosNew.g}
                checked={completed}
                onChange={(ev) => setCompleted(ev.target.checked)}
            />
            <div className="mbsc-button-group-justified cs-dashboard-todos-new-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-todos-new-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardTodosNew.b}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardTodosNew;

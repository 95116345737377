import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Popup, Input, Datepicker, Checkbox, confirm, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '../styles/dashboardTodosTodo.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardTodosTodo({Dialog, dialog, todo, locale, setRefresh, width, setToast}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [item, setItem] = useState(todo.item);
    const [list, setList] = useState(todo.list);
    const [completed, setCompleted] = useState(todo.completed);
    const [due, setDue] = useState(todo.due);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ItemSave = () => {
        if (!item) {
            setToast({message: locale.dashboardTodosTodo.e, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardTodosTodo.f, color: 'info', display: 'bottom', duration: 1000});
            if (due) {
                setDue(dayjs(due).format());
            }

            const message = {
                type: 'wss',
                path: 'todo',
                action: 'update',
                data: {}
            };
            if (item !== todo.item) {
                message.data.item = item;
            }
            if (completed !== todo.completed) {
                message.data.completed = completed;
            }
            if (due !== todo.due) {
                message.data.due = dayjs(due).format();
            }
            if (list !== todo.list) {
                message.data.list = list ? list : 'General';
            }
            wsCall(message);
        }
    }

    const ButtonDelete = () => {
        confirm({
            title: locale.dashboardTodosTodo.h,
            message: locale.dashboardTodosTodo.i,
            okText: locale.dashboardTodosTodo.d,
            cancelText: locale.dashboardTodosTodo.j,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'todo',
                        action: 'delete',
                        data: {
                            todo: todo
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonClose = () => {
        if (item !== todo.item ||
        list !== todo.list ||
        completed !== todo.completed ||
        due !== todo.due) {
            ItemSave();
        } else {
            DialogClose();
        }
    }

    const UpdateTodo = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
        }
        DialogClose();
    }, [DialogClose]);

    const DeleteTodo = useCallback(data => {
        if (data.toast) {
            setRefresh(true);
        }
        DialogClose();
    }, [DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'todo') {
            DeleteTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteTodo, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'todo') {
            UpdateTodo(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateTodo, setWsResponse]);

    return (
        <Popup className="cs-dashboard-todos-todo-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardTodosTodo'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-todos-todo-header-col">
                <div className="mbsc-row cs-dashboard-todos-todo-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-todos-todo-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-todos-todo-header-col mbsc-align-center" : "mbsc-col cs-dashboard-todos-todo-header-col"} >
                        <Button className="cs-dashboard-todos-todo-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardTodosTodo.a}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-todos-todo-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-todos-todo-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-todos-todo-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardTodosTodo.k} labelStyle="floating" type="text" name="list" value={list} onChange={(ev) => setList(ev.target.value)} />
            <Input inputStyle="underline" label={locale.dashboardTodosTodo.a} labelStyle="floating" type="text" name="title" value={item} onChange={(ev) => setItem(ev.target.value)} />
            <Datepicker
                controls={['date']}
                touchUi={width <= 576}
                inputStyle="underline"
                label={locale.dashboardTodosTodo.c}
                labelStyle="floating"
                buttons={['set', 'cancel']}
                dateFormat="YYYY-M-D"
                dateWheels="|YYYY | MMM | D|"
                value={due}
                selectMultiple={false}
                onChange={args => setDue(args.value)}
            />
            <Checkbox
                color="info"
                label={locale.dashboardTodosTodo.g}
                checked={completed}
                onChange={(ev) => setCompleted(ev.target.checked)}
            />
            <div className="mbsc-button-group-justified cs-dashboard-todos-todo-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-todos-todo-nav-button" onClick={() => ButtonDelete()} >
                    {locale.dashboardTodosTodo.d}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardTodosTodo;

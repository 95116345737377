import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Input, toast, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderTabNew.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderTabNew({locale, dialog, Dialog, tabs, setTabs, setMessages, setText, tab, Navigation, width, setWorkspace}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [name, setName] = useState(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        if (!name) {
            toast({message: locale.dashboardHeaderTabNew.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {
            const itemTab = {
                id: tab,
                name: ''
            }

            const matchingTab = tabs.find(data => data.id === tab);

            if (matchingTab) {
                itemTab.name = matchingTab.name;
            }

            const message = {
                type: 'wss',
                path: 'tab',
                action: 'put',
                data: {
                    name: name,
                    tabs: tabs
                }
            };
            wsCall(message);
        }
    }

    const PutTab = useCallback(data => {
        if (data.tabs) {
            setTabs(data.tabs);
        }
        if (data.tab) {
            setMessages([]);
            setWorkspace([]);
            setText('<p></p>');
            window.localStorage.text = '<p></p>';

            Navigation({tab: data.tab});
        }
        DialogClose();
    }, [DialogClose, tabs, Navigation]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'put' && wsResponse.path === 'tab') {
            PutTab(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, PutTab, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-tab-new-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderTabNew'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-tab-new-header-col">
                <div className="mbsc-row cs-dashboard-header-tab-new-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-tab-new-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-tab-new-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-tab-new-header-col"} >
                        <Button className="cs-dashboard-header-tab-new-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderTabNew.e}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-tab-new-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-tab-new-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-tab-new-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardHeaderTabNew.b} labelStyle="floating" type="text" name="account" value={name} onChange={ev => {setName(ev.target.value) }} />
            <div className="mbsc-button-group-justified cs-dashboard-header-tab-new-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-tab-new-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardHeaderTabNew.d}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardHeaderTabNew;

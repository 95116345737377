import React, {useCallback, useContext, useRef} from 'react';
import {Popup, Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceAdd.css';
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceAdd({Dialog, dialog, locale, setToast}) {
    const {wsCall} = useContext(NetworkContext);
    const menu = useRef(locale.dashboardWorkspaceAdd.a);
    const hiddenFileInput = useRef(null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ListItem = data => {
        let item = '';

        if (data.item.file) {
            item = <li key={data.item.timestamp} className="cs-dashboard-workspace-add-li">
                <Button color="primary" variant="flat" className="cs-dashboard-workspace-add-button mbsc-bold" onClick={() => hiddenFileInput.current.click()} >
                    {locale.dashboardWorkspaceAdd.b}
                </Button>
            </li>;
        }

        return item;
    }

    const InputUpload = data => {
        const itemFile = data.target.files[0];
        setToast({message: locale.dashboardWorkspaceAdd.c, color: 'info', display: 'bottom', duration: 1000});

        const reader = new FileReader();
        reader.addEventListener('load', ()=>{
            const itemData = reader.result;
            const itemName = itemFile.name;
            const itemType = itemFile.type;

            const message = {
                type: 'wss',
                path: 'workspace-file',
                action: 'put',
                data: {
                    file: itemData,
                    name: itemName,
                    type: itemType
                }
            };
            wsCall(message);
        })
        reader.readAsDataURL(itemFile);
    }

    return (
        <Popup className="cs-dashboard-workspace-add-popup" display="anchored" anchor={document.getElementById('dashboardWorkspaceAdd')} showArrow={false} buttons={[]} isOpen={dialog.view === 'dashboardWorkspaceAdd'} onClose={DialogClose} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={menu.current}
            />
            <div style={{display: 'none'}}>
                <input type="file" ref={hiddenFileInput} onChange={InputUpload} />
            </div>
        </Popup>
    );
}

export default DashboardWorkspaceAdd;

import React, {useCallback, useState, useContext, useEffect} from 'react';
import {Popup, Textarea, Button, Select, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderDalle.css';
import {NetworkContext} from "../utils/NetworkContext";
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderDalle({Dialog, dialog, locale, configuration, setToast, toast, width}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [image, setImage] = useState(null);
    const [prompt, setPrompt] = useState(null);
    const [resolution, setResolution] = useState(configuration.mobi.dalle.resolution[0].value);
    const [quality, setQuality] = useState(configuration.mobi.dalle.quality[0].value);
    const [style, setStyle] = useState(configuration.mobi.dalle.style[0].value);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonGenerate = () => {
        if (!prompt) {
            toast({message: locale.dashboardHeaderDalle.a, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            setToast({message: locale.dashboardHeaderDalle.g, color: 'primary', display: 'center', duration: false});
            setImage('');

            const message = {
                type: 'wss',
                path: 'dalle',
                action: 'get',
                data: {
                    prompt: prompt,
                    resolution: resolution,
                    quality: quality,
                    style: style
                }
            };
            wsCall(message);
        }
    }

    const ButtonOpen = () => {
        window.open(image, '_blank');
    }

    const GetDalle = useCallback(data => {
        if (data.image) {
            setImage(data.image);
        }
        if (data.error) {
            setToast({message: data.error, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'dalle') {
            GetDalle(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, GetDalle, setWsResponse]);

    return (
        <Popup className="cs-dashboard-header-dalle-popup" maxHeight={750} closeOnOverlayClick={false} closeOnEsc={false} scrollLock={true} display="center" width="600" buttons={[]} isOpen={dialog.view === 'dashboardHeaderDalle'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-header-dalle-header-col">
                <div className="mbsc-row cs-dashboard-header-dalle-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-dalle-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-dalle-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-dalle-header-col"} >
                        <Button className="cs-dashboard-header-dalle-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderDalle.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-dalle-header-col mbsc-align-right">
                        <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-dalle-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-dalle-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="cs-dashboard-header-dalle-helper">
                <p>{locale.dashboardHeaderDalle.e}</p>
            </div>
            <div className="cs-dashboard-header-dalle-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardHeaderDalle.d} value={prompt} onChange={(ev) => setPrompt(ev.target.value)} />
            </div>
            {image &&
                <div style={{backgroundImage: `url(${image})`}} className="cs-dashboard-header-dalle-image"  />
            }
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderDalle.f}
                labelStyle="floating"
                rows={configuration.mobi.dalle.resolution.length}
                display="anchored"
                touchUi={false}
                data={configuration.mobi.dalle.resolution}
                value={resolution}
                onChange={args => setResolution(args.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderDalle.i}
                labelStyle="floating"
                rows={configuration.mobi.dalle.quality.length}
                display="anchored"
                touchUi={false}
                data={configuration.mobi.dalle.quality}
                value={quality}
                onChange={args => setQuality(args.value)}
            />
            <Select
                animation="slide-down"
                inputStyle="underline"
                label={locale.dashboardHeaderDalle.j}
                labelStyle="floating"
                rows={configuration.mobi.dalle.style.length}
                display="anchored"
                touchUi={false}
                data={configuration.mobi.dalle.style}
                value={style}
                onChange={args => setStyle(args.value)}
            />
            <div className="mbsc-button-group-justified cs-dashboard-header-dalle-nav-buttons">
                <Button disabled={!image} color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-dalle-nav-button" onClick={() => ButtonOpen()} >
                    {locale.dashboardHeaderDalle.h}
                </Button>
                <Button disabled={!Empty(toast)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-dalle-nav-button" onClick={() => ButtonGenerate()} >
                    {locale.dashboardHeaderDalle.b}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardHeaderDalle;

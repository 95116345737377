import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {Popup, Button, setOptions, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {Listview} from "@mobiscroll/react4";
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import '../styles/dashboardCalendarRange.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendarRange({Dialog, dialog, locale, range, setRange, ui, setUi}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const menu = useRef(locale.dashboardCalendarRange.a);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ListItem = data => {
        let item = '';

        if (data.item.week) {
            item = <li key={data.item.timestamp} className="cs-dashboard-calendar-range-li">
                <Button disabled={range === 'week'} color="primary" variant="flat" className="cs-dashboard-calendar-range-button mbsc-bold" onClick={() => ButtonSelect('week')} >
                    {locale.dashboardCalendarRange.b}
                </Button>
            </li>;
        } else if (data.item.month) {
            item = <li key={data.item.timestamp} className="cs-dashboard-calendar-range-li">
                <Button disabled={range === 'month'} color="primary" variant="flat" className="cs-dashboard-calendar-range-button mbsc-bold" onClick={() => ButtonSelect('month')} >
                    {locale.dashboardCalendarRange.c}
                </Button>
            </li>;
        } else if (data.item.day) {
            item = <li key={data.item.timestamp} className="cs-dashboard-calendar-range-li">
                <Button disabled={range === 'day'} color="primary" variant="flat" className="cs-dashboard-calendar-range-button mbsc-bold" onClick={() => ButtonSelect('day')} >
                    {locale.dashboardCalendarRange.d}
                </Button>
            </li>;
        }

        return item;
    }

    const ButtonSelect = data => {
        setRange(data);

        const message = {
            type: 'wss',
            path: 'ui-calendar-range',
            action: 'update',
            data: {
                range: data,
                ui: ui
            }
        };
        wsCall(message);
    }

    const UpdateUiCalendarRange = useCallback(data => {
        if (data.toast){
            toast({message: locale.dashboardCalendarRange.e, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.ui){
            setUi(data.ui);
        }
        DialogClose();
    }, [locale.dashboardCalendarRange.e, DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'ui-calendar-range') {
            UpdateUiCalendarRange(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUiCalendarRange, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-range-popup" display="anchored" anchor={document.getElementById('dashboardCalendarRange')} showArrow={false} buttons={[]} isOpen={dialog.view === 'dashboardCalendarRange'} onClose={DialogClose} >
            <Listview
                theme="ios"
                themeVariant="light"
                select="single"
                itemType={ListItem}
                data={menu.current}
            />
        </Popup>
    );
}

export default DashboardCalendarRange;

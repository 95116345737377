import React, {useCallback, useContext, useState, useEffect} from 'react';
import {Popup, Input, Datepicker, Checkbox, Textarea, confirm, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import mobiscroll from '@mobiscroll/react4';
import '@mobiscroll/react4/dist/css/mobiscroll.min.css';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import '../styles/dashboardCalendarEvent.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendarEvent({Dialog, dialog, visit, locale, setRefresh, width, setToast, startTime, endTime, date, anchor}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [title, setTitle] = useState(visit.title);
    const [location, setLocation] = useState(visit.location);
    const [start, setStart] = useState(visit.start);
    const [end, setEnd] = useState(visit.end);
    const [color, setColor] = useState(visit.color);
    const [notes, setNotes] = useState(visit.notes);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ItemSave = () => {
        if (!title || !start || !end) {
            setToast({message: locale.dashboardCalendarEvent.m, color: 'danger', display: 'bottom', duration: 3000});
        } else {

            const message = {
                type: 'wss',
                path: 'calendar',
                action: 'update',
                data: {
                    event: visit,
                    title: title,
                    location: location,
                    start: dayjs(start).format(),
                    end: dayjs(end).format(),
                    color: color,
                    notes: notes
                }
            };
            wsCall(message);
        }
    }

    const ButtonDelete = () => {
        confirm({
            title: locale.dashboardCalendarEvent.o,
            message: locale.dashboardCalendarEvent.p,
            okText: locale.dashboardCalendarEvent.l,
            cancelText: locale.dashboardCalendarEvent.q,
            callback: (res) => {
                if (res) {
                    const message = {
                        type: 'wss',
                        path: 'calendar',
                        action: 'delete',
                        data: {
                            event: visit
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonClose = () => {
        if (title !== visit.title ||
        location !== visit.location ||
        start !== visit.start ||
        end !== visit.end ||
        color !== visit.color ||
        notes !== visit.notes) {
            ItemSave();
        } else {
            DialogClose();
        }
    }

    const UpdateCalendar = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardCalendarEvent.n, color: 'info', display: 'bottom', duration: 1000});
            setRefresh(true);
        }
        DialogClose();
    }, [DialogClose, locale.dashboardCalendarEvent.n]);

    const DeleteCalendar = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardCalendarEvent.j, color: 'info', display: 'bottom', duration: 1000});
            setRefresh(true);
        }
        DialogClose();
    }, [DialogClose, locale.dashboardCalendarEvent.j]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'calendar') {
            DeleteCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteCalendar, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'calendar') {
            UpdateCalendar(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateCalendar, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-event-popup" width={600} anchor={anchor ? anchor : null} display={anchor ? "anchored" : "center"} scrollLock={true} showArrow={false} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardCalendarEvent'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-calendar-event-header-col">
                <div className="mbsc-row cs-dashboard-calendar-event-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-calendar-event-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-calendar-event-header-col mbsc-align-center" : "mbsc-col cs-dashboard-calendar-event-header-col"} >
                        <Button className="cs-dashboard-calendar-event-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {title}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-calendar-event-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-event-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-calendar-event-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-grid cs-dashboard-calendar-event-grid">
                <div className="mbsc-row cs-dashboard-calendar-event-row">
                    <div className="mbsc-col cs-dashboard-calendar-event-grid">
                        <Datepicker
                            controls={['calendar', 'timegrid']}
                            touchUi={width <= 576}
                            inputStyle="underline"
                            label={locale.dashboardCalendarEvent.c}
                            labelStyle="floating"
                            value={start}
                            selectMultiple={false}
                            stepMinute={15}
                            minTime={startTime}
                            maxTime={endTime}
                            onChange={(args) => setStart(args.value)}
                            defaultSelection={date}
                            buttons={['set', 'cancel']}
                            setText={locale.dashboardCalendarEvent.i}
                            cancelText={locale.dashboardCalendarEvent.q}
                        />
                    </div>
                    <div className="mbsc-col cs-dashboard-calendar-event-grid">
                        <Datepicker
                            controls={['calendar', 'timegrid']}
                            touchUi={width <= 576}
                            inputStyle="underline"
                            label={locale.dashboardCalendarEvent.d}
                            labelStyle="floating"
                            value={end}
                            selectMultiple={false}
                            stepMinute={15}
                            minTime={start ? start : startTime}
                            maxTime={endTime}
                            onChange={(args) => setEnd(args.value)}
                            defaultSelection={start ? start : date}
                            buttons={['set', 'cancel']}
                            setText={locale.dashboardCalendarEvent.i}
                            cancelText={locale.dashboardCalendarEvent.q}
                        />
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardCalendarEvent.a} labelStyle="floating" type="text" name="title" value={title} onChange={(ev) => setTitle(ev.target.value)} />
            <Input inputStyle="underline" label={locale.dashboardCalendarEvent.b} labelStyle="floating" type="text" name="location" value={location} onChange={(ev) => setLocation(ev.target.value)} />
            <mobiscroll.Color theme="ios" themeVariant="light" touchUi={false} value={color} onSet={event => setColor(event.value)}>
                <mobiscroll.Input theme="ios" themeVariant="light" inputStyle="underline" >
                    {locale.dashboardCalendarEvent.e}
                </mobiscroll.Input>
            </mobiscroll.Color>
            <div className="cs-dashboard-calendar-event-textarea">
                <Textarea inputStyle="underline" labelStyle="floating" label={locale.dashboardCalendarEvent.f} value={notes} onChange={(ev) => setNotes(ev.target.value)} />
            </div>
            {visit.allDay &&
                <Checkbox
                    color="info"
                    label={locale.dashboardCalendarEvent.g}
                    checked={visit.allDay}
                    disabled
                    className="cs-dashboard-calendar-event-input"
                />
            }
            {visit.recurring &&
                <Input disabled inputStyle="underline" label={locale.dashboardCalendarEvent.h} labelStyle="floating" type="text" name="recurring" value={visit.recurring} />
            }
            <div className="mbsc-button-group-justified cs-dashboard-calendar-event-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-event-nav-button" onClick={() => ButtonDelete()} >
                    {locale.dashboardCalendarEvent.l}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardCalendarEvent;

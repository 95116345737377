import React, {useCallback} from 'react';
import {Popup, Select, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardQuestionMenu.css';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestionMenu({Dialog, dialog, locale, configuration, SelectModel, model, functions, SelectFunctions}) {

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    return (
        <Popup className="cs-dashboard-question-menu-popup" anchor={document.getElementById('dashboardQuestionMenu')} showArrow={false} display="anchored" buttons={[]} isOpen={dialog.view === 'dashboardQuestionMenu'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-question-menu-main">
                {configuration.ui.dashboard.model &&
                    <div className="mbsc-row">
                        <div className="mbsc-col-12 cs-dashboard-question-menu-section">
                            <Select
                                animation="slide-down"
                                inputStyle="underline"
                                dropdown={false}
                                label={locale.dashboardQuestionMenu.b}
                                labelStyle="floating"
                                rows={configuration.mobi.model.length}
                                display="anchored"
                                touchUi={false}
                                data={configuration.mobi.model}
                                value={model}
                                onChange={args => SelectModel(args.value)}
                            />
                        </div>
                    </div>
                }
                {configuration.ui.dashboard.functions &&
                    <div className="mbsc-row">
                        <div className="mbsc-col-12 cs-dashboard-question-menu-section">
                            <Select
                                animation="slide-down"
                                inputStyle="underline"
                                dropdown={false}
                                label={locale.dashboardQuestionMenu.a}
                                labelStyle="floating"
                                touchUi={false}
                                rows={configuration.mobi.functions.length}
                                display="anchored"
                                selectMultiple={true}
                                value={functions}
                                data={configuration.mobi.functions}
                                onChange={args => SelectFunctions(args.value)}
                            />
                        </div>
                    </div>
                }
            </div>
        </Popup>
    );
}

export default DashboardQuestionMenu;

import React, {useState, useContext, useCallback} from 'react';
import {Popup, Input, toast, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardHeaderReset.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardHeaderReset({locale, dialog, Dialog, width}) {
    const {wsCall} = useContext(NetworkContext);
    const [password, setPassword] = useState(null);
    const [confirm, setConfirm] = useState(null);

    const DialogClose = useCallback(() => {
        setPassword('');
        setConfirm('');
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonReset = () => {
        if (!password || !confirm) {
            toast({message: locale.dashboardHeaderReset.a, color: 'danger', display: 'bottom', duration: 3000});
        } else if (password !== confirm) {
            toast({message: locale.dashboardHeaderReset.b, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const message = {
                type: 'wss',
                path: 'user',
                action: 'update',
                data: {
                    password: password
                }
            };
            wsCall(message);
        }
    }

    return (
        <Popup className="cs-dashboard-header-reset-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardHeaderReset'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-header-reset-header-col">
                <div className="mbsc-row cs-dashboard-header-reset-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-header-reset-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-header-reset-header-col mbsc-align-center" : "mbsc-col cs-dashboard-header-reset-header-col"} >
                        <Button className="cs-dashboard-header-reset-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardHeaderReset.c}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-header-reset-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-reset-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-header-reset-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardHeaderReset.d} labelStyle="floating" type="password" name="password" passwordToggle={true} value={password} onChange={(ev) => setPassword(ev.target.value)} />
            <Input inputStyle="underline" label={locale.dashboardHeaderReset.e} labelStyle="floating" type="password" name="confirm" passwordToggle={true} value={confirm} onChange={(ev) => setConfirm(ev.target.value)} />
            <div className="mbsc-button-group-justified cs-dashboard-header-reset-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-header-reset-nav-button" onClick={() => ButtonReset()} >
                    {locale.dashboardHeaderReset.f}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardHeaderReset;

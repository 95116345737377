import React, {useContext, useState, useRef, useCallback, useEffect} from 'react';
import {Button, SegmentedGroup, Segmented, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceDoc.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {
    DecoupledEditor,
    Alignment,
    Autosave,
    BlockQuote,
    Bold,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Highlight,
    Indent,
    IndentBlock,
    Italic,
    List,
    PageBreak,
    Paragraph,
    RemoveFormat,
    Underline
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardWorkspaceDoc({locale, workspaceItem, setLoading}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const editorContainerRef = useRef(null);
    const editorUpdateContainerRef = useRef(null);
    const editorMenuBarRef = useRef(null);
    const editorToolbarRef = useRef(null);
    const editorRef = useRef(null);
    const editorUpdateRef = useRef(null);
    const [text, setText] = useState(workspaceItem.data.doc);
    const [menu, setMenu] = useState('original');

    const EditorUpdate = data => {
        setText(data);

        if (data && data.length % 10 === 0) {

            EditorSave(data);
        }
    }

    const EditorSave = data => {

        const message = {
            type: 'wss',
            path: 'doc',
            action: 'update',
            data: {
                item: workspaceItem.id,
                doc: data
            }
        };
        wsCall(message);
    }

    const ButtonAccept = () => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item-accept',
            action: 'update',
            data: {
                id: workspaceItem.id
            }
        };
        wsCall(message);
    }

    const ButtonReject = () => {
        setLoading(true);

        const message = {
            type: 'wss',
            path: 'workspace-item-reject',
            action: 'update',
            data: {
                id: workspaceItem.id
            }
        };
        wsCall(message);
    }

    const SegmentedUpdate = data => {
        setMenu(data.target.value);
    }

    const UpdateDoc = useCallback(data => {
        if (data.toast){

        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'doc') {
            UpdateDoc(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateDoc, setWsResponse]);

    return (
        <>
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-doc-header-secondary" >
                    <div className="mbsc-col" />
                    <div className="mbsc-col-auto" >
                        <div className="mbsc-row cs-dashboard-workspace-doc-col-end">
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-doc-color" onClick={() => ButtonAccept()} >
                                {locale.dashboardWorkspaceDoc.c}
                            </Button>
                            <Button color="primary" variant="flat" className="mbsc-bold mbsc-txt-muted cs-dashboard-workspace-doc-color" onClick={() => ButtonReject()} >
                                {locale.dashboardWorkspaceDoc.d}
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row mbsc-justify-content-center cs-dashboard-workspace-doc-section">
                    <div className="mbsc-col-12 mbsc-col-sm-12 mbsc-col-md-10 mbsc-col-lg-8 mbsc-col-xl-6">
                        <SegmentedGroup className="cs-dashboard-workspace-doc-update" name="update" color="primary" value={menu} onChange={SegmentedUpdate}>
                            <Segmented value="original" >
                                {locale.dashboardWorkspaceDoc.a}
                            </Segmented>
                            <Segmented value="update" >
                                {locale.dashboardWorkspaceDoc.b}
                            </Segmented>
                        </SegmentedGroup>
                    </div>
                </div>
            }
            {!workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-doc-section">
                    <div className="mbsc-col cs-dashboard-workspace-doc-editor">
                        <div className="cs-dashboard-workspace-doc-editor-main">
                            <div ref={editorContainerRef} className="cs-dashboard-workspace-doc-editor-container cs-dashboard-workspace-doc-editor-container_document-editor">
                                <div className="cs-dashboard-workspace-doc-editor-container__menu-bar" ref={editorMenuBarRef}></div>
                                <div className="cs-dashboard-workspace-doc-editor-container__toolbar" ref={editorToolbarRef}></div>
                                <div className="mbsc-justify-content-center cs-dashboard-workspace-doc-editor-container__editor-wrapper">
                                    <div className="cs-dashboard-workspace-doc-editor-container__editor">
                                        <div ref={editorRef}>
                                            <CKEditor
                                                onBlur={() => EditorSave(text)}
                                                editor={DecoupledEditor}
                                                onReady={editor => {
                                                    editorToolbarRef.current.appendChild(editor.ui.view.toolbar.element);
                                                    editorMenuBarRef.current.appendChild(editor.ui.view.menuBarView.element);
                                                }}
                                                onAfterDestroy={() => {
                                                    Array.from(editorToolbarRef.current.children).forEach(child => child.remove());
                                                    Array.from(editorMenuBarRef.current.children).forEach(child => child.remove());
                                                }}
                                                config={{
                                                    toolbar: {
                                                        items: ['fontSize',
                                                            'fontFamily',
                                                            'fontColor',
                                                            'fontBackgroundColor',
                                                            '|',
                                                            'bold',
                                                            'italic',
                                                            'underline',
                                                            '|',
                                                            'highlight',
                                                            'blockQuote',
                                                            '|',
                                                            'alignment',
                                                            '|',
                                                            'bulletedList',
                                                            'numberedList',
                                                            'outdent',
                                                            'indent'],
                                                        shouldNotGroupWhenFull: false
                                                    },
                                                    versionCheck: false,
                                                    plugins: [
                                                        Alignment,
                                                        Autosave,
                                                        BlockQuote,
                                                        Bold,
                                                        Essentials,
                                                        FontBackgroundColor,
                                                        FontColor,
                                                        FontFamily,
                                                        FontSize,
                                                        GeneralHtmlSupport,
                                                        Highlight,
                                                        Indent,
                                                        IndentBlock,
                                                        Italic,
                                                        List,
                                                        PageBreak,
                                                        Paragraph,
                                                        RemoveFormat,
                                                        Underline
                                                    ],
                                                    fontFamily: {
                                                        supportAllValues: true
                                                    },
                                                    fontSize: {
                                                        options: [10, 12, 14, 'default', 18, 20, 22],
                                                        supportAllValues: true
                                                    },
                                                    htmlSupport: {
                                                        allow: [
                                                            {
                                                                name: /^.*$/,
                                                                styles: true,
                                                                attributes: true,
                                                                classes: true
                                                            }
                                                        ]
                                                    },
                                                    menuBar: {
                                                        isVisible: true
                                                    },
                                                    licenseKey: 'GPL',
                                                    ui: {
                                                        poweredBy: {position:'inside', side:'right'}
                                                    }
                                                }}
                                                data={text}
                                                onChange={(event, editor) => {EditorUpdate(editor.getData())}}
                                                disableWatchdog={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {workspaceItem.update &&
                <div className="mbsc-row cs-dashboard-workspace-doc-section">
                    <div className="mbsc-col cs-dashboard-workspace-doc-editor">
                        <div className="cs-dashboard-workspace-doc-editor-main">
                            <div ref={editorUpdateContainerRef} className="cs-dashboard-workspace-doc-editor-container cs-dashboard-workspace-doc-editor-container_document-editor">
                                <div className="mbsc-justify-content-center cs-dashboard-workspace-doc-editor-container__editor-wrapper">
                                    <div className="cs-dashboard-workspace-doc-editor-container__editor">
                                        <div ref={editorUpdateRef}>
                                            <CKEditor
                                                editor={DecoupledEditor}
                                                onReady={editor => {
                                                    editor.enableReadOnlyMode('editor-dashboard-doc');
                                                }}
                                                config={{
                                                    toolbar: {
                                                        items: [],
                                                        shouldNotGroupWhenFull: false
                                                    },
                                                    versionCheck: false,
                                                    plugins: [
                                                        Alignment,
                                                        Autosave,
                                                        BlockQuote,
                                                        Bold,
                                                        Essentials,
                                                        FontBackgroundColor,
                                                        FontColor,
                                                        FontFamily,
                                                        FontSize,
                                                        GeneralHtmlSupport,
                                                        Highlight,
                                                        Indent,
                                                        IndentBlock,
                                                        Italic,
                                                        List,
                                                        PageBreak,
                                                        Paragraph,
                                                        RemoveFormat,
                                                        Underline
                                                    ],
                                                    fontFamily: {
                                                        supportAllValues: true
                                                    },
                                                    fontSize: {
                                                        options: [10, 12, 14, 'default', 18, 20, 22],
                                                        supportAllValues: true
                                                    },
                                                    htmlSupport: {
                                                        allow: [
                                                            {
                                                                name: /^.*$/,
                                                                styles: true,
                                                                attributes: true,
                                                                classes: true
                                                            }
                                                        ]
                                                    },
                                                    licenseKey: 'GPL',
                                                    ui: {
                                                        poweredBy: {position:'inside', side:'right'}
                                                    }
                                                }}
                                                data={menu === 'update' ? workspaceItem.data.update.doc : text}
                                                disableWatchdog={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default DashboardWorkspaceDoc;

import {useState, useCallback} from 'react';
import {snackbar} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import axios from 'axios';
import Decode from './Decode';

function REST(locale, token, session) {
    const [restResponse, setRestResponse] = useState(null);

    const restCall = useCallback((item) => {
        const url = Decode('aHR0cHM6Ly9jb25uZWN0LmN1Ymljc3RvbmUuY29t');

        if (item.action === 'get') {
            axios.get(url, {params: {...item, session, token}})
                .then(data => setRestResponse(data.data))
                .catch(error => {snackbar({message: locale.rest.a, color: 'danger', duration: false, display: 'bottom'})});
        } else if (item.action === 'update' || item.action === 'put') {
            axios.post(url, {...item, session, token})
                .then(data => setRestResponse(data.data))
                .catch(error => {snackbar({message: locale.rest.a, color: 'danger', duration: false, display: 'bottom'})});
        } else if (item.action === 'delete') {
            axios.delete(url, {data: {...item, session, token}})
                .then(data => setRestResponse(data.data))
                .catch(error => {snackbar({message: locale.rest.a, color: 'danger', duration: false, display: 'bottom'})});
        }
    }, [token, session, locale.rest.a]);

    return {restResponse, restCall, setRestResponse};
}

export default REST;

import React, {useCallback, useEffect, useState, useContext} from 'react';
import {SegmentedGroup, Segmented, Select, Input, Button, toast, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/login.css';
import LoginReset from "./dialogs/LoginReset";
import LoginRegister from "./dialogs/LoginRegister";
import Empty from './utils/Empty';
import {faSparkles} from "@fortawesome/pro-duotone-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NetworkContext} from './utils/NetworkContext';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

const StateType = () => {
    const item = window.localStorage.login;

    if (item) {
        return item;
    } else {
        window.localStorage.login = 'email'
        return 'email';
    }
}

const StateCountry = () => {
    const item = window.localStorage.country;
    if (!item) {
        window.localStorage.country = 'us';
        return 'us';
    } else {
        return item;
    }
}

function Login({wss, token, locale, dialog, Dialog, shortener, reset, setReset, account, configuration, Navigation, width}) {
    const {restCall, restResponse, setRestResponse} = useContext(NetworkContext);
    const [country, setCountry] = useState(StateCountry());
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [next, setNext] = useState(true);
    const [type, setType] = useState(StateType());

    const ButtonForgot = () => {
        if (!mobile) {
            toast({message: locale.login.h, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemData = {
                account: account
            }

            if (configuration.ui.login.email || (configuration.ui.login.multiple && type === 'email')) {
                itemData.id = email;
                itemData.type = 'email';
            } else if (configuration.ui.login.mobile || (configuration.ui.login.multiple && type === 'mobile')) {
                itemData.id = mobile.replace(/[()\s-]/g, '');
                itemData.country = country;
                itemData.type = 'mobile';
            } else if (configuration.ui.login.username || (configuration.ui.login.multiple && type === 'username')) {
                itemData.username = username;
                itemData.type = 'username';
            }

            const message = {
                type: 'rest',
                path: 'user-reset',
                action: 'update',
                data: itemData
            };
            restCall(message);
        }
    }

    const ButtonLogin = () => {
        if (!password) {
            toast({message: locale.login.q, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemData = {
                account: account,
                password: password
            }
            if (configuration.ui.login.email || (configuration.ui.login.multiple && type === 'email')) {
                itemData.id = email;
                itemData.type = 'email';
            } else if (configuration.ui.login.mobile || (configuration.ui.login.multiple && type === 'mobile')) {
                itemData.id = mobile.replace(/[()\s-]/g, '');
                itemData.country = country;
                itemData.type = 'mobile';
            } else if (configuration.ui.login.username || (configuration.ui.login.multiple && type === 'username')) {
                itemData.username = username;
                itemData.type = 'username';
            }

            const message = {
                type: 'rest',
                path: 'token',
                action: 'get',
                data: itemData
            };
            restCall(message);
        }
    }

    const ButtonNext = () => {
        if ((configuration.ui.login.email && !email) || (configuration.ui.login.multiple && type === 'email' && !email)) {
            toast({message: locale.login.r, color: 'danger', display: 'bottom', duration: 3000});
        } else if ((configuration.ui.login.mobile && !mobile) || (configuration.ui.login.multiple && type === 'mobile' && !mobile)) {
            toast({message: locale.login.h, color: 'danger', display: 'bottom', duration: 3000});
        } else if ((configuration.ui.login.username && !username) || (configuration.ui.login.multiple && type === 'username' && !username)) {
            toast({message: locale.login.s, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const item = {
                account: account
            }
            if (configuration.ui.login.email || (configuration.ui.login.multiple && type === 'email')) {
                item.id = email;
                item.type = 'email';
            } else if (configuration.ui.login.mobile || (configuration.ui.login.multiple && type === 'mobile')) {
                item.id = mobile.replace(/[()\s-]/g, '');
                item.country = country;
                item.type = 'mobile';
            } else if (configuration.ui.login.username || (configuration.ui.login.multiple && type === 'username')) {
                item.id = username;
                item.type = 'username';
            }

            const message = {
                type: 'rest',
                path: 'user',
                action: 'get',
                data: item
            };
            restCall(message);
        }
    }

    const ButtonRegister = () => {
        Dialog({view: 'loginRegister'});
    }

    const SelectUpdate = data => {
        window.localStorage.country = data;
        setCountry(data);
    }

    const InputMobile = data => {
        let formattedText = '';

        if (country === 'us') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 3) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 3)}) ${cleanedText.slice(3, 6)}-${cleanedText.slice(6, 10)}`;
            }
        } else if (country === 'mx') {
            const cleanedText = data.replace(/[^\d]/g, '');

            if (cleanedText.length <= 2) {
                formattedText = cleanedText;
            } else if (cleanedText.length <= 6) {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2)}`;
            } else {
                formattedText = `(${cleanedText.slice(0, 2)}) ${cleanedText.slice(2, 6)}-${cleanedText.slice(6, 10)}`;
            }
        }
        setMobile(formattedText);
    }

    const SegmentedType = data => {
        setType(data.target.value);
        window.localStorage.login = data.target.value;
    }

    const Shortener = useCallback(data => {
        toast({message: locale.login.i, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'user-shortener',
            action: 'get',
            data: {
                shortener: shortener
            }
        };
        restCall(message);
    }, [restCall, shortener, locale.login.i]);

    const Reset = useCallback(() => {
        toast({message: locale.login.l, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'rest',
            path: 'token-user',
            action: 'get',
            data: {
                token: reset
            }
        };
        restCall(message);
    }, [restCall, reset, locale.login.l]);

    const UpdateUserReset = useCallback(data => {
        if (data.error) {
            toast({message: locale.login.f, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.toast) {
            toast({message: locale.login.g, color: 'info', display: 'bottom', duration: 3000});
        }
    }, [locale.login.f, locale.login.g]);

    const GetToken = useCallback(data => {
        if (data.token) {
            window.localStorage.token = data.token;
            token.current = data.token;
        }
        if (data.url) {
            wss.current = data.url;
            Navigation({view: 'dashboard'});
        }
        if (data.error && data.user) {
            toast({message: locale.login.n, color: 'danger', display: 'bottom', duration: 3000});
        }
        if (data.error && data.account) {
            toast({message: locale.login.a, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.login.n, Navigation, locale.login.a]);

    const GetUserShortener = useCallback(data => {
        if (data.token) {
            window.localStorage.token = data.token;
            token.current = data.token;
        }
        if (data.url) {
            wss.current = data.url;
        }
        if (data.error) {
            toast({message: locale.login.j, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.login.j]);

    const GetTokenUser = useCallback(data => {
        if (data.mobile) {
            setMobile(data.mobile);
            Dialog({view: 'passwordReset'});
        }
        if (data.error) {
            toast({message: locale.login.j, color: 'danger', display: 'bottom', duration: 3000});
            setReset('');
        }
    }, [locale.login.j, Dialog]);

    const GetUser = useCallback(data => {
        if (data.toast) {
            setNext(false);
        }
        if (data.error) {
            toast({message: locale.login.f, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.login.f]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user') {
            GetUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUser, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'update' && restResponse.path === 'user-reset') {
            UpdateUserReset(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, UpdateUserReset, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token') {
            GetToken(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetToken, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'user-shortener') {
            GetUserShortener(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetUserShortener, setRestResponse]);

    useEffect(() => {
        if (restResponse && !Empty(restResponse) && restResponse.action === 'get' && restResponse.path === 'token-user') {
            GetTokenUser(restResponse.data);
            setRestResponse({});
        }
    }, [restResponse, GetTokenUser, setRestResponse]);

    useEffect(() => {
        if (shortener) {
            Shortener();
        }
    }, [shortener, Shortener]);

    useEffect(() => {
        if (reset) {
            Reset();
        }
    }, [reset, Reset]);

    return (
        <div className="mbsc-grid cs-login-main">
            {!Empty(configuration) &&
                <div className="mbsc-row mbsc-justify-content-center">
                    <div className="mbsc-col-sm-9 mbsc-col-md-7 mbsc-col-xl-5 cs-login-card">
                        <div style={{backgroundImage: `url(${configuration.logo})`}} className="cs-login-card-logo" />
                        <div className={(!next && configuration.ui.login.mobile) ? 'cs-login-header-mobile' : ''}>
                            {next && configuration.ui.login.title &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {configuration.title}
                                </h2>
                            }
                            {!next && (configuration.ui.login.mobile || (configuration.ui.login.multiple && type === 'mobile')) &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {mobile}
                                </h2>
                            }
                            {!next && (configuration.ui.login.email || (configuration.ui.login.multiple && type === 'email')) &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {email}
                                </h2>
                            }
                            {!next && (configuration.ui.login.username || (configuration.ui.login.multiple && type === 'username')) &&
                                <h2 className="cs-login-card-title mbsc-txt-muted">
                                    {username}
                                </h2>
                            }
                        </div>
                        {configuration.ui.login.multiple &&
                            <div className="mbsc-row">
                                <SegmentedGroup className="cs-login-nav" name="login" color="primary" value={type} onChange={SegmentedType}>
                                    {configuration.ui.login.email &&
                                        <Segmented value="email" >
                                            {locale.login.o}
                                        </Segmented>
                                    }
                                    {configuration.ui.login.mobile &&
                                        <Segmented value="mobile" >
                                            {locale.login.b}
                                        </Segmented>
                                    }
                                    {configuration.ui.login.username &&
                                        <Segmented value="username" >
                                            {locale.login.p}
                                        </Segmented>
                                    }
                                </SegmentedGroup>
                            </div>
                        }
                        {next && ((!configuration.ui.login.multiple && configuration.ui.login.mobile) || (configuration.ui.login.multiple && type === 'mobile')) &&
                            <div className="mbsc-row">
                                <div className="mbsc-col-4 cs-login-inputs-left">
                                    <Select
                                        animation="slide-down"
                                        dropdown={false}
                                        inputStyle="outline"
                                        label={locale.login.t}
                                        labelStyle="floating"
                                        rows={locale.login.u.length}
                                        display="anchored"
                                        touchUi={false}
                                        value={country}
                                        data={locale.login.u}
                                        disabled={mobile.length !== 0}
                                        onChange={args => SelectUpdate(args.value)}
                                    />
                                </div>
                                <div className="mbsc-col-8 cs-login-inputs-right">
                                    <Input className="cs-login-inputs-right-input" label={locale.login.b} inputStyle="outline" labelStyle="floating" type="text" name="mobile" value={mobile} onChange={ev => InputMobile(ev.target.value)} />
                                </div>
                            </div>
                        }
                        {next && ((!configuration.ui.login.multiple && configuration.ui.login.email) || (configuration.ui.login.multiple && type === 'email')) &&
                            <Input inputStyle="outline" label={locale.login.o} labelStyle="floating" type="email" name="Email" value={email} onChange={ev => setEmail(ev.target.value)} />
                        }
                        {next && ((!configuration.ui.login.multiple && configuration.ui.login.username) || (configuration.ui.login.multiple && type === 'username')) &&
                            <Input inputStyle="outline" label={locale.login.p} labelStyle="floating" type="text" name="Username" value={username} onChange={ev => setUsername(ev.target.value)} />
                        }
                        {!next &&
                            <Input inputStyle="outline" label={locale.login.c} labelStyle="floating" minLength="8" type="password" name="Password" passwordToggle={true} value={password} onChange={ev => setPassword(ev.target.value)} />
                        }
                        {next &&
                            <div className="mbsc-row mbsc-justify-content-end">
                                <div className="mbsc-col-4 mbsc-button-group-block">
                                    <Button color="primary" variant="flat" className="cs-login-button mbsc-bold" onClick={() => ButtonNext()} >
                                        {locale.login.k}
                                    </Button>
                                </div>
                            </div>
                        }
                        {!next &&
                            <div className="mbsc-row mbsc-justify-content-end">
                                <div className="mbsc-col-4 mbsc-button-group-block">
                                    <Button color="primary" variant="flat" className="cs-login-button mbsc-bold" onClick={() => ButtonLogin()} >
                                        {locale.login.e}
                                    </Button>
                                </div>
                            </div>
                        }
                        <div className="mbsc-row cs-login-footer-section">
                            <div className="mbsc-col cs-login-footer">
                                {configuration.ui.login.register && !configuration.stripe &&
                                    <Button className="cs-login-button-lang mbsc-bold" onClick={() => ButtonRegister()} variant="flat" >
                                        <FontAwesomeIcon className="cs-login-icon-secondary" icon={faSparkles} /> {locale.login.v}
                                    </Button>
                                }
                            </div>
                            <div className="mbsc-col-auto cs-login-footer">
                                {configuration.ui.login.forgot &&
                                    <Button className="cs-login-button-lang mbsc-bold" onClick={() => ButtonForgot()} variant="flat" >
                                        {locale.login.d}
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {!Empty(configuration) && dialog.view === 'loginReset' &&
                <LoginReset
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    reset={reset}
                    mobile={mobile}
                    width={width}
                />
            }
            {!Empty(configuration) && dialog.view === 'loginRegister' &&
                <LoginRegister
                    locale={locale}
                    dialog={dialog}
                    Dialog={Dialog}
                    account={account}
                />
            }
        </div>
    );
}

export default Login;

import React, {useCallback, useEffect, useState, useContext} from 'react';
import {Snackbar, Toast, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import './styles/dashboard.css';
import {Chart as ChartJS, CategoryScale, LinearScale, ArcElement, BarElement,  PointElement, LineElement, Title, Tooltip, Legend, Filler, PolarAreaController, RadialLinearScale} from 'chart.js';
import DashboardHeader from "./blocks/DashboardHeader";
import DashboardCalendar from './blocks/DashboardCalendar';
import DashboardTodos from './blocks/DashboardTodos';
import DashboardNotes from './blocks/DashboardNotes';
import DashboardWorkspace from './blocks/DashboardWorkspace';
import DashboardQuestion from './blocks/DashboardQuestion';
import DashboardConversation from './blocks/DashboardConversation';
import DashboardInfo from './dialogs/DashboardInfo';
import Empty from './utils/Empty';
import HTMLPlain from './utils/HTMLPlain';
import {NetworkContext} from "./utils/NetworkContext";

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler, PolarAreaController, RadialLinearScale);

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function Dashboard({width, locale, configuration, dialog, Dialog, Navigation, navigation, setConfiguration, setLocale, token}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [snackbar, setSnackbar] = useState({});
    const [timer, setTimer] = useState(null);
    const [elapsed, setElapsed] = useState(0);
    const [messageAnchor, setMessageAnchor] = useState({});
    const [messageData, setMessageData] = useState({});
    const [functions, setFunctions] = useState([]);
    const [system, setSystem] = useState(false);
    const [loading, setLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [tabs, setTabs] = useState([]);
    const [workspace, setWorkspace] = useState([]);
    const [ui, setUi] = useState({});
    const [text, setText] = useState('<p></p>');
    const [calendar, setCalendar] = useState([]);
    const [model, setModel] = useState(null);
    const [user, setUser] = useState({});
    const [toast, setToast] = useState({});
    const [broadcast, setBroadcast] = useState('');

    const Start = () => {
        setToast({message: locale.dashboard.a, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'wss',
            path: 'user-login',
            action: 'get',
            data: {}
        };
        wsCall(message);
    }

    const Vision = data => {
        return configuration.mobi.model.find(item => item.value === data)?.vision || null;
    }

    const Locale = data => {
        if (data === 'es' && data !== window.localStorage.locale) {
            setLocale(configuration.locale.es);
            window.localStorage.locale = 'es';
        } else if (data === 'en' && data !== window.localStorage.locale) {
            setLocale(configuration.locale.en);
            window.localStorage.locale = 'en';
        }
    }

    const Conversation = () => {
        setToast({message: locale.dashboard.b, color: 'info', display: 'bottom', duration: 1000});

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'get',
            data: {
                system: system
            }
        };
        wsCall(message);
    }

    const Read = data => {
        setToast({message: locale.dashboard.d, color: 'primary', display: 'center', duration: false});

        const message = {
            type: 'wss',
            path: 'read',
            action: 'get',
            data: {
                text: data
            }
        };
        wsCall(message);
    }

    const Delete = data => {
        confirm({
            title: locale.dashboard.j,
            message: locale.dashboard.k,
            okText: locale.dashboard.l,
            cancelText: locale.dashboard.m,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboard.b, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'conversation-item',
                        action: 'delete',
                        data: {
                            item: data,
                            system: system
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const GetUserLogin = useCallback(data => {
        if (data.mobiFunctions) {
            let item = {...configuration};
            item.mobi.functions = data.mobiFunctions;
            setConfiguration(item);
        }
        if (data.tabs) {
            setTabs(data.tabs);
        }
        if (data.ui) {
            setUi(data.ui);
        }
        if (data.tab) {
            Navigation({tab: data.tab});
        }
        if (data.model) {
            setModel(data.model);
        }
        if (data.messages) {
            setMessages(data.messages);
        }
        if (data.functions) {
            setFunctions(data.functions);
        }
        if (window.localStorage.text) {
            setText(window.localStorage.text);
        }
        if (data.user) {
            setUser(data.user);
            Locale(data.user.locale);
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        setLoading(false);
    }, [configuration, Locale, Navigation]);

    const GetConversation = useCallback(data => {
        if (data.model) {
            setModel(data.model);
        }
        if (data.messages) {
            setMessages(data.messages);
        }
        if (data.functions) {
            setFunctions(data.functions);
        }
        if (data.tab) {
            Navigation({tab: data.tab});
        }
        if (data.ui) {
            setUi(data.ui);
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (dialog.view) {
            Dialog({view: 'close'});
        }
    }, [Dialog, dialog.view, Navigation]);

    const GetTab = useCallback(data => {
        if (data.model) {
            setModel(data.model);
        }
        if (data.messages) {
            setMessages(data.messages);
        }
        if (data.functions) {
            setFunctions(data.functions);
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (data.tab) {
            Navigation({tab: data.tab});
        }
    }, [Navigation]);

    const DeleteTab = useCallback(data => {
        if (data.model) {
            setModel(data.model);
        }
        if (data.messages) {
            setMessages(data.messages);
        }
        if (data.functions) {
            setFunctions(data.functions);
        }
        if (data.tabs) {
            setTabs(data.tabs);
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (data.tab) {
            Navigation({tab: data.tab});
        }
    }, [Navigation]);

    const GetRead = useCallback(data => {
        if (data.audio) {
            const binaryString = window.atob(data.audio);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            const blob = new Blob([bytes], {type: 'audio/mpeg'});
            const url = URL.createObjectURL(blob);
            const audio = new Audio(url);
            audio.play().catch((error) => {
                console.error('Error playing audio:', error);
            });
        }
        if (data.error) {
            setToast({message: locale.dashboard.c, color: 'danger', display: 'bottom', duration: 3000});
        }
    }, [locale.dashboard.c]);

    const BroadcastMessage = useCallback(data => {
        if (data.message) {
            setBroadcast(HTMLPlain(data.message));
        }
    }, []);

    const DeleteConversationItem = useCallback(data => {
        if (data.messages) {
            setMessages(data.messages);
        }
    }, []);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'conversation-item') {
            DeleteConversationItem(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, DeleteConversationItem, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'read') {
            GetRead(wsResponse.data);
            setWsResponse({});
            setToast({});
        }
    }, [wsResponse, GetRead, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'broadcast' && wsResponse.path === 'message') {
            BroadcastMessage(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, BroadcastMessage, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'conversation') {
            GetConversation(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetConversation, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'delete' && wsResponse.path === 'tab') {
            DeleteTab(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, DeleteTab, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'tab') {
            GetTab(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetTab, setWsResponse]);

    useEffect(() => {
        if (wsResponse && wsResponse && !Empty(wsResponse) && wsResponse.action === 'get' && wsResponse.path === 'user-login') {
            GetUserLogin(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, GetUserLogin, setWsResponse]);

    useEffect(() => {
        if (toast.duration === false && elapsed === 0) {
            const interval = setInterval(() => {
                setElapsed((prevSeconds) => prevSeconds + 1);
            }, 1000);
            setTimer(interval);
        } else if ((Empty(toast) || toast.duration !== false) && elapsed !== 0) {
            setBroadcast('');
            clearInterval(timer);
            setElapsed(0);
        }
    }, [toast]);

    useEffect(() => {
        if (broadcast && elapsed < 60) {
            setToast((prevToast) => ({
                ...prevToast,
                message: broadcast + '...' + elapsed + ' ' + locale.dashboard.g,
            }));
        } else if (!broadcast && elapsed >= 5 && elapsed <= 15) {
            setToast((prevToast) => ({
                ...prevToast,
                message: locale.dashboard.e + '...' + elapsed + ' ' + locale.dashboard.g,
            }));
        } else if (!broadcast && elapsed > 15 && elapsed <= 60) {
            setToast((prevToast) => ({
                ...prevToast,
                message: locale.dashboard.f + '...' + elapsed + ' ' + locale.dashboard.g,
            }));
        } else if (elapsed > 60) {
            setSnackbar({message: locale.dashboard.h + '...' + elapsed + ' ' + locale.dashboard.g, color: 'info', display: 'center', duration: false})
        }
    }, [elapsed, broadcast]);

    useEffect(() => {
        Start();
    }, []);

  return (
      <>
          {tabs && tabs.length !== 0 &&
              <DashboardHeader
                  width={width}
                  locale={locale}
                  dialog={dialog}
                  Dialog={Dialog}
                  configuration={configuration}
                  user={user}
                  Conversation={Conversation}
                  functions={functions}
                  toast={toast}
                  system={system}
                  Navigation={Navigation}
                  navigation={navigation}
                  setMessages={setMessages}
                  setLocale={setLocale}
                  setUser={setUser}
                  token={token}
                  setToast={setToast}
                  tabs={tabs}
                  setTabs={setTabs}
                  setText={setText}
                  setWorkspace={setWorkspace}
              />
          }
          <div className={width > 768 ? "mbsc-grid cs-dashboard-grid" : "mbsc-grid cs-dashboard-grid-small"}>
              {!Empty(user) &&
                  <DashboardQuestion
                      width={width}
                      configuration={configuration}
                      Dialog={Dialog}
                      dialog={dialog}
                      toast={toast}
                      system={system}
                      locale={locale}
                      setModel={setModel}
                      setFunctions={setFunctions}
                      model={model}
                      functions={functions}
                      text={text}
                      setText={setText}
                      setToast={setToast}
                      setMessages={setMessages}
                      Navigation={Navigation}
                      Conversation={Conversation}
                      loading={loading}
                      messages={messages.length}
                      tabs={tabs}
                      setSystem={setSystem}
                      setSnackbar={setSnackbar}
                      setWorkspace={setWorkspace}
                      workspace={workspace}
                      navigation={navigation}
                  />
              }
              {navigation.card === 'calendar' &&
                  <DashboardCalendar
                      locale={locale}
                      Navigation={Navigation}
                      navigation={navigation}
                      width={width}
                      calendar={calendar}
                      setCalendar={setCalendar}
                      Dialog={Dialog}
                      dialog={dialog}
                      setToast={setToast}
                      ui={ui}
                      setUi={setUi}
                  />
              }
              {navigation.card === 'todos' &&
                  <DashboardTodos
                      locale={locale}
                      Navigation={Navigation}
                      Dialog={Dialog}
                      navigation={navigation}
                      dialog={dialog}
                      width={width}
                      setToast={setToast}
                  />
              }
              {navigation.card === 'notes' &&
                  <DashboardNotes
                      locale={locale}
                      Navigation={Navigation}
                      width={width}
                      setToast={setToast}
                  />
              }
              {navigation.card === 'workspace' &&
                  <DashboardWorkspace
                      locale={locale}
                      Navigation={Navigation}
                      width={width}
                      setToast={setToast}
                      workspace={workspace}
                      toast={toast}
                      Dialog={Dialog}
                      setWorkspace={setWorkspace}
                      dialog={dialog}
                  />
              }
              {messages && messages.length !== 0 && messages.map((item) => {
                  return (
                      <DashboardConversation
                          width={width}
                          locale={locale}
                          configuration={configuration}
                          item={item}
                          Dialog={Dialog}
                          setMessageAnchor={setMessageAnchor}
                          setMessageData={setMessageData}
                          user={user}
                          Read={Read}
                          system={system}
                          Delete={Delete}
                      />
                  );
              })}
          </div>
          {dialog.view === 'dashboardInfo' &&
              <DashboardInfo
                  Dialog={Dialog}
                  dialog={dialog}
                  messageData={messageData}
                  setMessageData={setMessageData}
                  messageAnchor={messageAnchor}
                  configuration={configuration}
                  setMessageAnchor={setMessageAnchor}
              />
          }
          <Snackbar
              isOpen={!Empty(snackbar)}
              onClose={() => setSnackbar({})}
              message={snackbar.message}
              duration={snackbar.duration}
              color={snackbar.color}
              display={snackbar.display}
              button={{
                  text: locale.dashboard.i,
                  action: () => {
                      setSnackbar({})
                  }
              }}
          />
          <Toast
              isOpen={!Empty(toast)}
              message={toast.message}
              duration={toast.duration}
              color={toast.color}
              display={toast.display}
              onClose={() => setToast({})}
          />
      </>
  );
}

export default Dashboard;

import React, {useState} from 'react';
import {Button, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBrainCircuit, faMessageLines, faWaveformLines, faMessageXmark, faArrowUpToLine, faArrowDownFromLine} from "@fortawesome/pro-duotone-svg-icons";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {BalloonEditor, Autosave, Essentials, GeneralHtmlSupport, HtmlComment, Paragraph} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '../styles/dashboardConversation.css';
import HTMLPlain from '../utils/HTMLPlain';

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardConversation({width, item, configuration, locale, Dialog, setMessageAnchor, setMessageData, user, Read, system, Delete}) {
    const [info, setInfo] = useState(false);
    const [hidden, setHidden] = useState(false);

    const ButtonFold = () => {
        if (hidden) {
            setHidden(false);
        } else {
            setHidden(true);
        }
    }

    const ButtonUserFold = data => {
        const plainText = HTMLPlain(data);
        let itemLength = 15;

        if (width > 992) {
            itemLength = 45;
        } else if (width < 992 && width > 768) {
            itemLength = 30;
        } else if (width < 768) {
            itemLength = 15;
        }

        return plainText.length > itemLength
            ? plainText.substring(0, itemLength) + '...'
            : plainText;
    }

    const ButtonModel = data => {
        const item = configuration.mobi.model.find(item => item.value === data);
        return item ? item.short : '';
    }

    const ButtonInfo = data => {
        if ((width > 768 && info) || (width < 768 && info && !data)) {
            setInfo(false);
        } else if ((width > 768 && !info) || (width < 768 && !info && !data)) {
            setInfo(true);
        } else if (width < 768 && data) {
            setInfo(false);
            setMessageAnchor(document.getElementById(data));
            const itemInfo = {
                model: item.model ? item.model : null,
                functions: item.functions ? item.functions : null,
                usage: item.usage ? item.usage : null,
                context: item.context ? item.context : null
            }
            setMessageData(itemInfo);
            Dialog({view: 'dashboardInfo'});
        }
    }

    const ButtonUser = () => {
        if (user.type === 'mobile') {
            return UserMobile(user.id);
        } else {
            return user.id;
        }
    }

    const ButtonToolDetail = data => {
        if (data.tool_calls && Array.isArray(data.tool_calls) && data.tool_calls.length !== 0) {
            const textContent = data.tool_calls.find(toolItem => toolItem?.function && toolItem.function.name);

            if (textContent) {
                return textContent.function.name;
            }
        } else if (data.content && Array.isArray(data.content) && data.content.length !== 0) {
            const textContent = data.content.find(toolItem => toolItem?.type && toolItem.type === 'tool_use');

            if (textContent) {
                return textContent.name;
            }
        }
    }

    const UserMobile = data => {
        let formattedText = '';

        if (data.startsWith('+1')) {
            const number = data.slice(2);

            formattedText = `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`;
        } else if (data.startsWith('+52')) {
            const number = data.slice(3);

            formattedText = `(${number.slice(0, 2)}) ${number.slice(2, 6)}-${number.slice(6, 10)}`;
        } else {
            formattedText = data;
        }

        return formattedText;
    }

    const EditorContent = data => {
        if (data.content && typeof data.content === 'string') {

            return data.content;
        } else if (data.content && typeof data.content === 'object' && Array.isArray(data.content)) {
            const textContent = data.content.find(contentItem => contentItem?.type && (contentItem.type === 'text' || contentItem.type === 'tool_result'));

            if (textContent) {
                return textContent.text ? textContent.text : textContent.content;
            }
        } else if (!data.content && data.tool_calls && typeof data.tool_calls === 'object') {
            let jsonString = JSON.stringify(data.tool_calls, null, 2);

            jsonString = jsonString.replace(/\n/g, '<br>');
            jsonString = jsonString.replace(/^( +)/gm, match => '&nbsp;'.repeat(match.length));
            jsonString = jsonString.replace(/"([^"]+)":/g, '$1:');

            return jsonString;
        }
    }

    const ToolUse = data => {
        if (data.tool_calls) {
            return true;
        } else if (data.content && Array.isArray(data.content) && data.content.length !== 0 && data.content.some(contentItem => typeof contentItem === 'object' && contentItem !== null && (contentItem.type === 'tool_use' || contentItem.type === 'tool_result'))) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="mbsc-row cs-dashboard-conversation-header">
                <div className="mbsc-col cs-dashboard-conversation-col-start">
                    {item.role === 'assistant' && !ToolUse(item) &&
                        <Button id={item.id} onClick={() => ButtonInfo(item.id)} color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info && !configuration.ui.header.personality &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {configuration.ui.dashboard.model ? ButtonModel(item.model) : locale.dashboardConversation.h}
                                    {item.context && item.usage && ' - ' + item.usage.total_tokens + ' of ' + item.context + ' tokens'}
                                </div>
                            }
                            {!info && hidden && width > 375 &&
                                <div className="cs-dashboard-conversation-menu-text mbsc-txt-muted">
                                    {ButtonUserFold(EditorContent(item))}
                                </div>
                            }
                        </Button>
                    }
                    {item.role === 'assistant' && ToolUse(item) &&
                        <Button onClick={() => ButtonInfo()} color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {locale.dashboardConversation.b}: {ButtonToolDetail(item)}
                                </div>
                            }
                            {!info && hidden && width > 375 &&
                                <div className="cs-dashboard-conversation-menu-text mbsc-txt-muted">
                                    {ButtonUserFold(EditorContent(item))}
                                </div>
                            }
                        </Button>
                    }
                    {item.role === 'system' &&
                        <Button onClick={() => ButtonInfo()} color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {locale.dashboardConversation.a}
                                </div>
                            }
                            {!info && hidden && width > 375 &&
                                <div className="cs-dashboard-conversation-menu-text mbsc-txt-muted">
                                    {ButtonUserFold(EditorContent(item))}
                                </div>
                            }
                        </Button>
                    }
                    {(item.role === 'tool' || (item.role === 'user' && ToolUse(item))) &&
                        <Button onClick={() => ButtonInfo()} color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color" >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faBrainCircuit} />{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {locale.dashboardConversation.c}
                                </div>
                            }
                            {!info && hidden && width > 375 &&
                                <div className="cs-dashboard-conversation-menu-text mbsc-txt-muted">
                                    {ButtonUserFold(EditorContent(item))}
                                </div>
                            }
                        </Button>
                    }
                    {item.role === 'user' && !ToolUse(item) &&
                        <Button onClick={() => ButtonInfo()} color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color" >
                            {user.avatar ? <div style={{backgroundImage: "url(https://www.gravatar.com/avatar/" + user.avatar + "?d=robohash)"}} className={width > 768 ? "cs-dashboard-conversation-avatar" : "cs-dashboard-conversation-avatar-xs"} /> : <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faMessageLines} />}{width > 768 && '\u00A0'}
                            {info &&
                                <div className="cs-dashboard-conversation-menu-text">
                                    {ButtonUser()}
                                </div>
                            }
                            {!info && hidden && width > 375 &&
                                <div className="cs-dashboard-conversation-menu-text mbsc-txt-muted">
                                    {ButtonUserFold(EditorContent(item))}
                                </div>
                            }
                        </Button>
                    }
                </div>
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-conversation-col-end">
                        {!system && item.role === 'assistant' && item.content && !item.function_call &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color"  onClick={() => Read(Array.isArray(item.content) ? item.content[0].text : item.content)} >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faWaveformLines} />{width > 768 && <div className="cs-dashboard-conversation-menu-text">{locale.dashboardConversation.k}</div>}
                            </Button>
                        }
                        {system && item.role !== 'system' &&
                            <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color"  onClick={() => Delete(item.id)} >
                                <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={faMessageXmark} />
                            </Button>
                        }
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-conversation-color" onClick={() => ButtonFold()} >
                            <FontAwesomeIcon className="cs-dashboard-conversation-icon" icon={hidden ? faArrowDownFromLine : faArrowUpToLine} />{(width > 768 || (width < 768 && width > 375 && hidden)) && '\u00A0'}
                        </Button>
                    </div>
                </div>
            </div>
            {!hidden &&
                <div className="mbsc-row cs-dashboard-conversation-main">
                    <div className="mbsc-col cs-dashboard-conversation-editor">
                        <CKEditor
                            onReady={editor => {
                                editor.enableReadOnlyMode('editor-dashboard-conversation');
                            }}
                            editor={BalloonEditor}
                            data={EditorContent(item)}
                            disableWatchdog={true}
                            config={{
                                toolbar: {
                                    items: [],
                                    shouldNotGroupWhenFull: true
                                },
                                plugins: [
                                    Autosave,
                                    Essentials,
                                    GeneralHtmlSupport,
                                    HtmlComment,
                                    Paragraph
                                ],
                                htmlSupport: {
                                    allow: [
                                        {
                                            name: /^.*$/,
                                            styles: true,
                                            attributes: true,
                                            classes: true
                                        }
                                    ]
                                },
                                licenseKey: 'GPL',
                                ui: {
                                    poweredBy: {position:'inside', side:'right'}
                                }
                            }}
                        />
                    </div>
                </div>
            }
        </>
    );
}

export default DashboardConversation;

import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Input, toast, setOptions, Button} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardWorkspaceRename.css';
import Empty from "../utils/Empty";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboarWorkspaceRename({locale, dialog, Dialog, width, workspaceItemDialog, setWorkspace}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [name, setName] = useState(workspaceItemDialog.name);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ItemSave = () => {
        if (!name) {
            toast({message: locale.dashboardWorkspaceRename.c, color: 'danger', display: 'bottom', duration: 3000});
        } else if (name) {

            const message = {
                type: 'wss',
                path: 'workspace-name',
                action: 'update',
                data: {
                    item: workspaceItemDialog.id,
                    name: name
                }
            };
            wsCall(message);
        }
    }

    const ButtonClose = () => {
        if (name !== workspaceItemDialog.name) {
            ItemSave();
        } else {
            DialogClose();
        }
    }

    const UpdateWorkspaceName = useCallback(data => {
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        DialogClose();
    }, [DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'workspace-name') {
            UpdateWorkspaceName(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateWorkspaceName, setWsResponse]);

    return (
        <Popup className="cs-dashboard-workspace-rename-popup" width={600} display="center" scrollLock={true} closeOnOverlayClick={false} closeOnEsc={false} buttons={[]} isOpen={dialog.view === 'dashboardWorkspaceRename'} onClose={DialogClose}>
            <div className="mbsc-grid cs-dashboard-workspace-rename-header-col">
                <div className="mbsc-row cs-dashboard-workspace-rename-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-workspace-rename-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-workspace-rename-header-col mbsc-align-center" : "mbsc-col cs-dashboard-workspace-rename-header-col"} >
                        <Button className="cs-dashboard-workspace-rename-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardWorkspaceRename.e}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-workspace-rename-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-workspace-rename-close-button" onClick={() => ButtonClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-workspace-rename-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Input inputStyle="underline" label={locale.dashboardWorkspaceRename.b} labelStyle="floating" type="text" name="account" value={name} onChange={ev => setName(ev.target.value)} />
        </Popup>
    );
}

export default DashboarWorkspaceRename;

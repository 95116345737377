import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Popup, Button, setOptions, Datepicker, toast} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../styles/dashboardCalendarHours.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import {NetworkContext} from "../utils/NetworkContext";

dayjs.extend(utc);
setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardCalendarHours({Dialog, dialog, locale, width, ui, setUi, startTime, endTime, setStartTime, setEndTime}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [hours, setHours] = useState((startTime && endTime) ? [startTime, endTime] : null);

    const DialogClose = useCallback(() => {
        Dialog({view: 'close'});
    }, [Dialog]);

    const ButtonSave = () => {
        if (!hours || hours.length !== 2) {
            toast({message: locale.dashboardCalendarHours.d, color: 'danger', display: 'bottom', duration: 3000});
        } else {
            const itemStart = dayjs(hours[0]).format('HH:mm');
            const itemEnd = dayjs(hours[1]).format('HH:mm');

            const message = {
                type: 'wss',
                path: 'ui-calendar-hours',
                action: 'update',
                data: {
                    startTime: itemStart,
                    endTime: itemEnd,
                    ui: ui
                }
            };
            wsCall(message);
        }
    }

    const UpdateUiCalendarHours = useCallback(data => {
        if (data.toast){
            toast({message: locale.dashboardCalendarHours.c, color: 'info', display: 'bottom', duration: 1000});
        }
        if (data.ui){
            setUi(data.ui);
        }
        if (data.ui.calendarStartTime) {
            setStartTime(data.ui.calendarStartTime);
        }
        if (data.ui.calendarEndTime) {
            setEndTime(data.ui.calendarEndTime);
        }
        DialogClose();
    }, [locale.dashboardCalendarHours.c, DialogClose]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'ui-calendar-hours') {
            UpdateUiCalendarHours(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateUiCalendarHours, setWsResponse]);

    return (
        <Popup className="cs-dashboard-calendar-hours-popup" display="anchored" anchor={document.getElementById('dashboardCalendarHours')} showArrow={false} buttons={[]} isOpen={dialog.view === 'dashboardCalendarHours'} onClose={DialogClose} >
            <div className="mbsc-grid cs-dashboard-calendar-hours-header-col">
                <div className="mbsc-row cs-dashboard-calendar-hours-header-row">
                    {width > 576 &&
                        <div className="mbsc-col cs-dashboard-calendar-hours-header-col" />
                    }
                    <div className={width > 576 ? "mbsc-col cs-dashboard-calendar-hours-header-col mbsc-align-center" : "mbsc-col cs-dashboard-calendar-hours-header-col"} >
                        <Button className="cs-dashboard-calendar-hours-title-button mbsc-bold mbsc-txt-muted" variant="flat" >
                            {locale.dashboardCalendarHours.b}
                        </Button>
                    </div>
                    <div className="mbsc-col cs-dashboard-calendar-hours-header-col mbsc-align-right">
                        <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-hours-close-button" onClick={() => DialogClose()} >
                            &nbsp;<FontAwesomeIcon className="cs-dashboard-calendar-hours-close-icon" icon={faXmark} />
                        </Button>
                    </div>
                </div>
            </div>
            <Datepicker
                controls={['timegrid']}
                touchUi={width <= 576}
                stepMinute={60}
                select="range"
                value={hours}
                display="inline"
                onChange={ev => setHours(ev.value)}
            />
            <div className="mbsc-button-group-justified cs-dashboard-calendar-hours-nav-buttons">
                <Button color="primary" variant="flat" className="mbsc-bold cs-dashboard-calendar-hours-nav-button" onClick={() => ButtonSave()} >
                    {locale.dashboardCalendarHours.a}
                </Button>
            </div>
        </Popup>
    );
}

export default DashboardCalendarHours;

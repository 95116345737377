import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Select, Button, confirm, setOptions} from "@mobiscroll/react";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {BalloonEditor, Autosave, BlockQuote, BlockToolbar, Bold, Essentials, Italic, Paragraph, RemoveFormat, Subscript, Superscript, Underline, CodeBlock} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';
import '../styles/dashboardQuestion.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane, faEllipsisStrokeVertical, faArrowRotateRight, faTrashCan, faCode, faBoxTaped, faObjectsColumn} from "@fortawesome/pro-duotone-svg-icons";
import Empty from "../utils/Empty";
import DashboardQuestionMenu from "../dialogs/DashboardQuestionMenu";
import {NetworkContext} from "../utils/NetworkContext";

setOptions({
    theme: 'ios',
    themeVariant: 'light',
});

function DashboardQuestion({width, configuration, Dialog, dialog, toast, system, locale, setModel, setFunctions, model, functions, text, setText, setToast, setMessages, Navigation, loading, messages, tabs, setSystem, setSnackbar, setWorkspace, workspace, navigation}) {
    const {wsCall, wsResponse, setWsResponse} = useContext(NetworkContext);
    const [menu, setMenu] = useState(false);

    const EditorUpdate = data => {
        let item = data;
        if (!item) {
            item = '<p></p>'
        }
        setText(item);
        window.localStorage.text = item;
    }

    const SelectModel = data => {
        setModel(data);

        const message = {
            type: 'wss',
            path: 'conversation-model',
            action: 'update',
            data: {
                model: data
            }
        };
        wsCall(message);
    }

    const SelectFunctions = data => {
        setFunctions(data);

        const message = {
            type: 'wss',
            path: 'conversation-function',
            action: 'update',
            data: {
                functions: data
            }
        };
        wsCall(message);
    }

    const ButtonDelete = data => {
        confirm({
            title: locale.dashboardQuestion.ae,
            message: locale.dashboardQuestion.af,
            okText: locale.dashboardQuestion.x,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboardQuestion.ag, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'tab',
                        action: 'delete',
                        data: {
                            tabs: tabs
                        }
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonMessage = () => {
        setToast({message: locale.dashboardQuestion.v, color: 'primary', display: 'center', duration: false});
        setMenu(false);

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'update',
            data: {
                text: text,
                model: model,
                functions: functions
            }
        };
        wsCall(message);
    }

    const ButtonRestart = () => {
        confirm({
            title: locale.dashboardQuestion.ab,
            message: locale.dashboardQuestion.ac,
            okText: locale.dashboardQuestion.aa,
            cancelText: locale.dashboardQuestion.ad,
            callback: (res) => {
                if (res) {
                    setToast({message: locale.dashboardQuestion.z, color: 'info', display: 'bottom', duration: 1000});

                    const message = {
                        type: 'wss',
                        path: 'conversation-reset',
                        action: 'update',
                        data: {}
                    };
                    wsCall(message);
                }
            }
        });
    }

    const ButtonMenu = () => {
        if (width > 992 && menu) {
            setMenu(false);
        } else if (width > 992 && !menu) {
            setMenu(true);
        } else if (width < 992) {
            setMenu(false);
            Dialog({view: 'dashboardQuestionMenu'})
        }
    }

    const ButtonSystem = () => {

        const message = {
            type: 'wss',
            path: 'conversation',
            action: 'get',
            data: {}
        };
        if (system) {
            setSystem(false);
            message.data.system = false;
        } else {
            setSystem(true);
            message.data.system = true;
        }
        wsCall(message);
    }

    const ButtonWorkspace = () => {
        Navigation({card: 'workspace'});
    }

    const ToolsSelect = data => {
        return configuration.mobi.model.find(item => item.value === data)?.tools || null;
    }

    const UpdateConversationReset = useCallback(data => {
        if (data.toast) {
            setMessages([]);
            setText('<p></p>');
            window.localStorage.text = '<p></p>';
        }
    }, []);

    const UpdateConversation = useCallback(data => {
        if (data.messages) {
            setMessages(data.messages);
            setText('<p></p>');
        }
        if (data.workspace) {
            setWorkspace(data.workspace);
        }
        if (data.error) {
            setToast({message: data.text, color: 'danger', display: 'bottom', duration: 3000});
        }
        Navigation({card: 'close'});
        window.scrollTo(0, 0);
    }, [Navigation]);

    const UpdateConversationModel = useCallback(data => {
        if (data.toast && !(model.includes('vision') || model.includes('Vision'))) {
            setToast({message: locale.dashboardQuestion.p, color: 'info', display: 'bottom', duration: 1000});
        } else if (data.toast && (model.includes('vision') || model.includes('Vision'))) {
            setToast({message: locale.dashboardQuestion.q, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardQuestion.p, model, locale.dashboardQuestion.q]);

    const UpdateConversationFunction = useCallback(data => {
        if (data.toast) {
            setToast({message: locale.dashboardQuestion.p, color: 'info', display: 'bottom', duration: 1000});
        }
    }, [locale.dashboardQuestion.p]);

    useEffect(() => {
        if (!Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-reset') {
            UpdateConversationReset(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationReset, setWsResponse]);

    useEffect(() => {
        if (wsResponse && wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-function') {
            UpdateConversationFunction(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationFunction, setWsResponse]);

    useEffect(() => {
        if (wsResponse && wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation-model') {
            UpdateConversationModel(wsResponse.data);
            setWsResponse({});
        }
    }, [wsResponse, UpdateConversationModel, setWsResponse]);

    useEffect(() => {
        if (wsResponse && !Empty(wsResponse) && wsResponse.action === 'update' && wsResponse.path === 'conversation') {
            UpdateConversation(wsResponse.data);
            setWsResponse({});
            setToast({});
            setSnackbar({});
        }
    }, [wsResponse, UpdateConversation, setWsResponse]);

    return (
        <>
            <div className="mbsc-row cs-dashboard-question-header" >
                <div className="mbsc-col" />
                <div className="mbsc-col-auto">
                    <div className="mbsc-row cs-dashboard-question-col-end">
                        <Button disabled={messages === 0 || !Empty(toast) || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => ButtonRestart()} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faArrowRotateRight} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.w}</div>}
                        </Button>
                        <Button disabled={!Empty(toast) || tabs.length < 2 || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => ButtonDelete()} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faTrashCan} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.x}</div>}
                        </Button>
                        <Button disabled={!Empty(toast) || messages === 0 || system} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={() => Dialog({view: 'dashboardHeaderConversationSave'})} >
                            <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faBoxTaped} />{width > 768 && <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.d}</div>}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="mbsc-row cs-dashboard-question-main">
                <div className="mbsc-col cs-dashboard-question-body">
                    <CKEditor
                        editor={BalloonEditor}
                        config={{
                            toolbar: {
                                items: ['bold', 'italic', 'underline', 'subscript', 'superscript', 'removeFormat', '|', 'blockQuote', 'codeBlock'],
                                shouldNotGroupWhenFull: false
                            },
                            blockToolbar: ['bold', 'italic', '|', 'blockQuote', 'codeBlock'],
                            versionCheck: false,
                            plugins: [
                                Autosave,
                                BlockQuote,
                                BlockToolbar,
                                Bold,
                                Essentials,
                                Italic,
                                Paragraph,
                                RemoveFormat,
                                Subscript,
                                Superscript,
                                Underline,
                                CodeBlock
                            ],
                            licenseKey: 'GPL',
                            ui: {
                                poweredBy: {position:'inside', side:'right'}
                            }
                        }}
                        data={text}
                        onChange={(event, editor) => {EditorUpdate(editor.getData())}}
                        disableWatchdog={true}
                    />
                </div>
            </div>
            {!loading &&
                <div className="mbsc-row cs-dashboard-question-footer">
                    <div className="mbsc-col">
                        <div className="mbsc-row cs-dashboard-question-col-start" >
                            {(configuration.ui.dashboard.model || configuration.ui.dashboard.functions) &&
                                <Button className="mbsc-bold cs-dashboard-question-color" color="primary" variant="flat" id="dashboardQuestionMenu" disabled={Boolean(!Empty(toast) || system)} onClick={() => ButtonMenu()} >
                                    <FontAwesomeIcon color={(!Empty(toast) || system) ? 'secondary' : 'primary'} className="cs-dashboard-question-icon" icon={faEllipsisStrokeVertical} />&nbsp;
                                </Button>
                            }
                            <div className="mbsc-col cs-dashboard-question-select" >
                                {configuration.ui.dashboard.model && menu &&
                                    <Select
                                        animation="slide-down"
                                        inputStyle="underline"
                                        dropdown={false}
                                        label={locale.dashboardQuestion.e}
                                        labelStyle="floating"
                                        disabled={!Empty(toast) || system}
                                        rows={configuration.mobi.model.length}
                                        display="anchored"
                                        touchUi={false}
                                        data={configuration.mobi.model}
                                        value={model}
                                        onChange={args => SelectModel(args.value)}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="mbsc-col-auto">
                        <div className="mbsc-row cs-dashboard-question-col-end">
                            <Button disabled={Boolean(!Empty(toast) || system || navigation.card === 'workspace')} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={(ev) => ButtonWorkspace()} >
                                <div className="cs-dashboard-question-badge">
                                    <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faObjectsColumn} />
                                    {workspace.length > 0 && (
                                        <span className="cs-dashboard-question-badge-icon">{workspace.length}</span>
                                    )}
                                </div>
                                <div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.f}</div>
                            </Button>
                            <Button disabled={!Empty(toast) || system || !text || text === '<p></p>'} color="primary" variant="flat" className="mbsc-bold cs-dashboard-question-color" onClick={(ev) => ButtonMessage()} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faPaperPlane} /><div className="cs-dashboard-question-menu-text">{locale.dashboardQuestion.a}</div>
                            </Button>
                        </div>
                    </div>
                </div>
            }
            {configuration.ui.dashboard.functions && menu &&
                <div className="mbsc-row cs-dashboard-question-footer">
                    <div className="mbsc-col cs-dashboard-question-select">
                        {ToolsSelect(model) &&
                            <Select
                                animation="slide-down"
                                inputStyle="underline"
                                dropdown={false}
                                label={locale.dashboardQuestion.c}
                                labelStyle="floating"
                                disabled={!Empty(toast) || system}
                                touchUi={false}
                                rows={configuration.mobi.functions.length}
                                display="anchored"
                                selectMultiple={true}
                                value={functions}
                                data={configuration.mobi.functions}
                                onChange={args => SelectFunctions(args.value)}
                            />
                        }
                    </div>
                    <div className="mbsc-col-auto cs-dashboard-question-col-end">
                        {configuration.ui.dashboard.system && menu &&
                            <Button className="cs-dashboard-question-color mbsc-bold" disabled={!Empty(toast)} color="primary" variant="flat" onClick={() => ButtonSystem()} >
                                <FontAwesomeIcon className="cs-dashboard-question-icon" icon={faCode} />&nbsp;
                            </Button>
                        }
                    </div>
                </div>
            }
            {dialog.view === 'dashboardQuestionMenu' &&
                <DashboardQuestionMenu
                    Dialog={Dialog}
                    dialog={dialog}
                    locale={locale}
                    configuration={configuration}
                    SelectModel={SelectModel}
                    model={model}
                    functions={functions}
                    SelectFunctions={SelectFunctions}
                />
            }
        </>
    );
}

export default DashboardQuestion;
